import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import L, { DivIcon } from 'leaflet';
import 'primeicons/primeicons.css';
import { CameraMarkerProps, plateEventResponse } from '../../types/types';
import CardCamera from './card-camera';


const createDivIcon = (color: string): DivIcon => {
    return L.divIcon({
        html: `<i class="pi pi-video" style="font-size:32px;color:${color};"></i>`,
        className: '',
        iconSize: [32, 32],
        iconAnchor: [16, 32],
    });
};

const CameraMarker: React.FC<CameraMarkerProps> = React.memo((props: CameraMarkerProps) => {

    const lastEvent = props.cameraEvents[props.camera.id];
    const markerRef = useRef<L.Marker<any>>(null); // Referência ao Marker para controle do popup
    const [popupData, setPopupData] = useState<plateEventResponse | null>(null); // Dados para o popup

    const activeCameraIcon = useMemo(() => createDivIcon('green'), []); // Ícone verde
    const signalrInactiveIcon = useMemo(() => createDivIcon('orange'), []); // Ícone laranja
    const inactiveCameraIcon = useMemo(() => createDivIcon('red'), []); // Ícone vermelho

    // Determina o ícone da câmera com base no estado dela
    const cameraIconInstance = useMemo(() => {
        if (!props.camera.indAtivo) {
            return inactiveCameraIcon;
        } else if (props.camera.indAtivo && props.camera.indSignalR) {
            return activeCameraIcon;
        } else if (props.camera.indAtivo && !props.camera.indSignalR) {
            return signalrInactiveIcon;
        }
    }, [props.camera.indAtivo, props.camera.indSignalR]);

    const adjustCoordinates = (lat: number, lng: number): [number, number] => {
        // Only adjust if needed; otherwise, return as is
        return [lat > 0 ? -lat : lat, lng > 0 ? -lng : lng];
    };

    const [adjustedLatitude, adjustedLongitude] = adjustCoordinates(props.camera.numLatitude, props.camera.numLongitude);

    // Abre o popup dinamicamente quando o activePopupCameraId mudar
    useEffect(() => {
        if (!props.clickedCamera && props.activePopupCameraId === props.camera.id && markerRef.current) {
            setPopupData(props.readPlate);
            markerRef.current.openPopup(); // Abre o popup
            setTimeout(() => {
                markerRef.current?.closePopup(); // Fecha o popup após 3 segundos
            }, 3000);
        } else {
            if (props.clickedCamera && lastEvent){
                setPopupData(lastEvent);
            }
        }
    }, [props.activePopupCameraId, props.camera.id, props.clickedCamera]);


    // Função para lidar com o clique no marcador
    const handleMarkerClick = () => {
        props.onClickedCamera(true);
        markerRef.current?.openPopup(); // Abre o popup ao clicar
    };

    // Função para lidar com o fechamento do popup
    const handlePopupClose = () => {
        props.onClickedCamera(false);
        markerRef.current?.closePopup(); // Fecha o popup após 3 segundos
    };

    return (
        <Marker
            position={[adjustedLatitude, adjustedLongitude]}
            icon={cameraIconInstance}
            ref={markerRef}
            eventHandlers={{ click: handleMarkerClick }}
        >
            {/* Tooltip exibido ao passar o mouse */}
            <Tooltip direction="top" offset={[0, -40]}>
                {props.camera.dscCamera}
            </Tooltip>

            {/* Popup exibido ao clicar ou quando activePopupCameraId corresponde */}
            <Popup
                offset={[0, -20]}
                eventHandlers={{
                    remove: handlePopupClose, // Usar o evento 'remove' para detectar o fechamento do popup
                }}
            >
                <CardCamera
                    readPlate={popupData} // Exibe os dados atualizados ou null
                    camera={props.camera}
                    vehicleCount={props.vehicleCount}
                />
            </Popup>
        </Marker>
    );
});

export default CameraMarker;
