import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { PageHeader } from '../../../views/page-header';
import { ClipLoader } from 'react-spinners';
import { Button } from 'primereact/button';
import { VehicleDetectionServices } from '../../../services/vehicle-detection-services';
import { LocationServices } from '../../../services/location-services';
import { DropdownInfo } from '../../../models/types';
import { vehicleType } from '../../../shared/constants/vehicle-type';
import { vehicleColor } from '../../../shared/constants/vehicle-color';
import { translateColor, untranslateColor } from "../../../services/utils/input-services";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { VehicleDetectionListDataTable } from '../data-tables/vehicle-detection-list-data-table';
import { VehicleBehaviorListDataTable } from '../data-tables/vehicle-behavior-list-data-table';

export const BehaviorReport = () => {
    const _vehicleDetectionServices = new VehicleDetectionServices();

    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedPlate, setSelectedPlate] = useState<string>("");
    const [initialDate, setInitialDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    const [vehicleData, setVehicleData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    // Função para buscar o histórico de veículos com base nos filtros
    const searchVehicleData = async () => {
        setLoading(true);
        try {

            const response = await _vehicleDetectionServices.getVehicleBehaviorReport(selectedPlate, initialDate, finalDate);
            setVehicleData(response.data || []);
        } catch (error) {
            console.error("Erro ao buscar histórico de veículos", error);
        }
        setLoading(false);
    };

    const isOlderDateSelectedFinal = (selectedDate: Date): boolean => {
        if (finalDate) {
            let dateDay: number = selectedDate.getDate();
            let finalDay: number = finalDate.getDate();

            let dateMonth: number = selectedDate.getMonth();
            let finalMonth: number = finalDate.getMonth();

            let dateYear: number = selectedDate.getFullYear();
            let finalYear: number = finalDate.getFullYear();

            return dateDay > finalDay || dateMonth > finalMonth || dateYear > finalYear;
        } else {
            return true;
        }
    };

    const isNewerDateSelectedInitial = (selectedDate: Date): boolean => {
        if (initialDate != null) {
            let dateDay: number = selectedDate.getDate();
            let initialDay: number = initialDate.getDate();

            let dateMonth: number = selectedDate.getMonth();
            let initialMonth: number = initialDate.getMonth();

            let dateYear: number = selectedDate.getFullYear();
            let initialYear: number = initialDate.getFullYear();
            return dateDay < initialDay || dateMonth < initialMonth || dateYear < initialYear;
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <div className="p-fluid p-grid">
                <div className="col-12">
                    <PageHeader
                        title="COMPORTAMENTO E PADRÕES DE VEÍCULOS"
                        icon="pi pi-car"
                    />
                    <span className="titulo-categoria">PLACA E TEMPORIZAÇÂO:</span>
                    <div className="categoria">
                        <div className="card">
                            <div className="grid p-fluid">

                                <div className="col-12 md:col-6 lg:col-4">
                                    <label htmlFor="plate">Placa</label>
                                    <InputText
                                        id="plate"
                                        value={selectedPlate}  // Estado para a placa
                                        onChange={(e) => setSelectedPlate(e.target.value)}  // Atualiza o valor da placa
                                        placeholder="Digite a placa do veículo"
                                    />
                                </div>
                                <div className="col-12 md:col-6 lg:col-4">
                                    <label htmlFor="initialDate">Data de Início</label>
                                    <Calendar
                                        id="initialDate"
                                        value={initialDate}
                                        onChange={(e: any) => setInitialDate(e.value)}
                                        disabled={loading || loading || loading}
                                        showIcon
                                        placeholder="Selecione a Data de Início menor que a Data de Fim"
                                        dateFormat="dd/mm/yy"
                                        className={classNames({ "p-invalid": initialDate && finalDate != null && isOlderDateSelectedFinal(finalDate) })}
                                    />
                                </div>

                                <div className="col-12 md:col-6 lg:col-4">
                                    <label htmlFor="finalDate">Data de Fim</label>
                                    <Calendar
                                        id="finalDate"
                                        value={finalDate}
                                        onChange={(e: any) => setFinalDate(e.value)}
                                        disabled={loading || loading || loading}
                                        showIcon
                                        placeholder="Selecione a Data de Fim maior que a Data de Início"
                                        dateFormat="dd/mm/yy"
                                        // className={classNames({ "p-invalid": finalDate && initialDate != null && isNewerDateSelectedInitial(initialDate) })}
                                        className={classNames({ "p-invalid": initialDate != null && isNewerDateSelectedInitial(initialDate) })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <Button
                        label="Buscar Dados"
                        icon="pi pi-search"
                        onClick={searchVehicleData}
                        disabled={loading || loading || loading}
                        loading={loading}
                    />

                    <VehicleBehaviorListDataTable
                        initialDate={initialDate}
                        finalDate={finalDate}
                        selectedPlate={selectedPlate}
                        setGlobalFilter={setGlobalFilter}
                        vehicleDataBehavior={vehicleData}
                        loading={loading}
                        globalFilter={globalFilter}
                    />

                </div>
                <div className='col-1'>
                    <ClipLoader color='var(--text-color)' loading={loading} size={25} />
                </div>
            </div>
        </React.Fragment >
    );
};
