import classNames from "classnames";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "prismjs/themes/prism-coy.css";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import "./App.css";
import "./App.scss";
import { AppFooter } from "./AppFooter";
import { AppTopbar } from "./AppTopbar";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import { BikerBehaviorConfiguration } from "./pages/Biker-Behavior/views/biker-behavior-configuration";
import { EventBikerBehavior } from "./pages/Biker-Behavior/views/event-biker-behavior";
import { BlackList } from "./pages/Black-list/views/black-list";
import { EventBlackList } from "./pages/Black-list/views/event-black-list";
import { ConvoyData } from "./pages/Camera-Convoy/views/convoy-data";
import { ClonedVehicleData } from "./pages/Camera-Cloned-Vehicle/views/cloned-vehicle-data";
import { CameraNoPlateConfiguration } from "./pages/Camera-No-Plate/views/camera-no-plate-configuration";
import { CrudCamera } from "./pages/Camera/views/camera-data";
import { CustomAlarm } from "./pages/Custom-alarm/views/custom-alarm-data";
import { Dashboard } from "./pages/Dashboard/views/dashboard-data";
import { DestinationAB } from "./pages/Destination-ab/views/destination-ab-data";
import { Dss } from "./pages/Dss/views/dss-data";
import { EventAB } from "./pages/Event-ab/views/event-ab-data";
import { EventBehavioralConvoy } from "./pages/Event-behabioral-convoy/views/event-convoy-data";
import { EventConvoy } from "./pages/Event-convoy/views/event-convoy-data";
import { EventClonedVehicle } from "./pages/Event-cloned-vehicle/views/event-cloned-vehicle-data";
import { EventCustomAlarm } from "./pages/Event-custom-alarm/views/event-custom-alarm-data";
import { EventNoPlate } from "./pages/Event-no-plate/views/event-no-plate-data";
import { Features } from "./pages/Features/views/features-data";
import Login from "./pages/Login/views/login-data";
import { Monitoring } from "./pages/Monitoring/views/monitoring";
import { PasswordReset } from "./pages/Password-Recovery/views/password-reset-data";
import { SearchCar } from "./pages/Search-car/views/search-car-data";
import { User } from "./pages/User/views/user-data";
import { Profile } from "./pages/Profile/views/user-profile";
import { WhatsApp } from "./pages/Whatsapp/views/whatsapp-data";
import { WhatsappFetures } from "./pages/Whatsapp/views/whatsapp-features-data";
import { DsmMonitoringMWS } from "./pages/DSM-Monitor/views/dsm-monitor-data-mws";
import { DsmMonitoringMQR } from "./pages/DSM-Monitor/views/dsm-monitor-data-mqr";
import { DsmMonitoringRED } from "./pages/DSM-Monitor/views/dsm-monitor-data-red";
import { DsmMonitoringCameras } from "./pages/DSM-Monitor/views/dsm-monitor-data-cameras";
import { DSMApplication } from "./pages/DSM-Application/views/dsm-application-data";
import { Map } from "./pages/Map/views/map-data";
// import blackLogo from './wwroot/img/moon-black-logo.png';
// import whiteLogo from './wwroot/img/moon-white-logo.png';
import AdminRoute from "./AdminRoute";
import { ReportHistory } from "./pages/Vehicle-Detection/views/report-history";
import { BehaviorReport } from "./pages/Vehicle-Detection/views/behavior-report";

const App = () => {
    const ripple = true;
    const location = useLocation();
    const inputStyle = "filled"; // Corrigido o inputStyle, definindo-o corretamente
    const layoutMode = "static";
    const darkTheme = 'mdc-dark-indigo';
    const lightTheme = 'mdc-light-indigo';
    const pathToHideMenuBar = ["/login", "/password-reset"];
    const userThemeSetting = localStorage.getItem('user-theme') ?? 'claro';

    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(true);
    const [theme, setTheme] = useState(userThemeSetting === 'claro' ? lightTheme : darkTheme);
    const [layoutColorMode, setLayoutColorMode] = useState(userThemeSetting === 'claro' ? 'light' : 'dark');
    // const [availableHeight, setAvailableHeight] = useState("100vh");

    const topbarRef = useRef(null);
    const footerRef = useRef(null);


    let menuClick = false;
    let mobileTopbarMenuClick = false;

    // Função auxiliar para adicionar classe ao elemento
    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    }

    // Função auxiliar para remover classe do elemento
    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    }

    // Função para substituir links, definida para evitar erro de ESLint
    const replaceLink = (linkElement, href, callback) => {
        if (window.navigator.userAgent.includes("MSIE") || window.navigator.userAgent.includes("Trident")) {
            linkElement.setAttribute("href", href);
            if (callback) callback();
        } else {
            const cloneLinkElement = linkElement.cloneNode(true);
            cloneLinkElement.setAttribute("href", href);
            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.onload = () => {
                linkElement.remove();
                if (callback) callback();
            };
        }
    }

    useEffect(() => {
        const updateBodyHeight = () => {
            const topbarHeight = topbarRef.current?.offsetHeight || 0;
            const footerHeight = footerRef.current?.offsetHeight || 0;

            // Calcula a altura disponível descontando o topo e o rodapé
            const contentHeight = `calc(100vh - ${topbarHeight + footerHeight}px)`;
            // setAvailableHeight(contentHeight);

            // Define variáveis CSS globais para uso nos estilos
            document.documentElement.style.setProperty('--app-topbar-height', `${topbarHeight}px`);
            document.documentElement.style.setProperty('--app-footer-height', `${footerHeight}px`);
            document.documentElement.style.setProperty('--app-content-height', contentHeight);
        };

        updateBodyHeight();
        window.addEventListener("resize", updateBodyHeight);

        return () => window.removeEventListener("resize", updateBodyHeight);
    }, []);

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        let themeElement = document.getElementById("theme-link");
        const themeHref = "assets/themes/" + theme + "/theme.css";
        replaceLink(themeElement, themeHref);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.documentElement.style.fontSize = "12px";
    }, []);

    const changeTheme = () => {
        let _userTheme = localStorage.getItem("user-theme") || "claro";

        if (_userTheme === "claro") {
            setTheme(darkTheme);
            setLayoutColorMode('dark');
            localStorage.setItem("user-theme", "escuro");
        } else {
            setTheme(lightTheme);
            setLayoutColorMode('light');
            localStorage.setItem("user-theme", "claro");
        }
        window.location.reload();
    }

    const onWrapperClick = () => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }
        menuClick = false;
        mobileTopbarMenuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === "overlay") {
                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const isDesktop = () => window.innerWidth >= 992;

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === 'light',
    });

    // Definindo a imagem com base no tema
    // const getImageSource = () => userThemeSetting === 'claro' ? blackLogo : whiteLogo;

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <ToastContainer />
            {!pathToHideMenuBar.includes(location.pathname) && (
                <div ref={topbarRef}> {/* Adicionando referência */}
                    <AppTopbar
                        theme={theme}
                        layoutColorMode={layoutColorMode}
                        onToggleMenuClick={onToggleMenuClick}
                        handleChangeTheme={changeTheme}
                        mobileTopbarMenuActive={mobileTopbarMenuActive}
                        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                    />
                </div>
            )}

            <div className="layout-main-container">
                <div className="layout-main">
                    <AdminRoute path="/usuario" component={User} />
                    <AdminRoute path="/dss-client" component={Dss} />
                    <AdminRoute path="/features" component={Features} />
                    <AdminRoute path="/camera" component={CrudCamera} />
                    <AdminRoute path="/monitoring" component={Monitoring} />
                    <AdminRoute path="/dsm-application" component={DSMApplication} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/login" component={Login} />
                    <Route path="/event-ab" component={EventAB} />
                    <Route path="/whatsapp" component={WhatsApp} />
                    <Route path="/search-car" component={SearchCar} />
                    <Route path="/black-list" component={BlackList} />
                    <Route path="/custom-alarm" component={CustomAlarm} />
                    <Route path="/event-convoy" component={EventConvoy} />
                    <Route path="/event-no-plate" component={EventNoPlate} />
                    <Route path="/password-reset" component={PasswordReset} />
                    <Route path="/destination-ab" component={DestinationAB} />
                    <Route path="/dsm-monitor-mws" component={DsmMonitoringMWS} />
                    <Route path="/dsm-monitor-mqr" component={DsmMonitoringMQR} />
                    <Route path="/dsm-monitor-red" component={DsmMonitoringRED} />
                    <Route path="/black-list-search" component={EventBlackList} />
                    <Route path="/whatsapp-features" component={WhatsappFetures} />
                    <Route path="/event-custom-alarm" component={EventCustomAlarm} />
                    <Route path="/event-cloned-vehicle" component={EventClonedVehicle} />
                    <Route path="/dsm-monitor-cameras" component={DsmMonitoringCameras} />
                    <Route path="/camera-convoy-configuration" component={ConvoyData} />
                    <Route path="/event-biker-behavior" component={EventBikerBehavior} />
                    <Route path="/event-behavioral-convoy" component={EventBehavioralConvoy} />
                    <Route path="/camera-cloned-vehicle-configuration" component={ClonedVehicleData} />
                    <Route path="/camera-no-plate-configuration" component={CameraNoPlateConfiguration} />
                    <Route path="/biker-behavior-configuration" component={BikerBehaviorConfiguration} />
                    {/* <Route path="/documentation" component={Documentation} /> */}
                    <Route path="/map" render={() => <Map/>} />
                    <Route path="/report-history" component={ReportHistory} />
                    <Route path="/behavior-report" component={BehaviorReport} />
                    <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                </div>
            </div>

            {/* Ref para o AppFooter */}
            <div ref={footerRef}>
                <AppFooter />
            </div>

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
