import React, { useState } from "react"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { Toolbar } from "primereact/toolbar"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { listRouteResponse, RouteListDataTableProps, routeResponse } from "../types/types"
import { Dialog } from "primereact/dialog"
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../wwroot/img/olho-vivo-logo.png"

export const RouteListDataTable = (props: RouteListDataTableProps) => {

    const [isListRoutePopupOpen, setIsListRoutePopupOpen] = useState<boolean>(false);
    const [routes, setRoutes] = useState<routeResponse[]>([]);
    const [selectedRoute, setSelectedRoute] = useState<listRouteResponse | null>(null);


    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Lista de veículos</h5>
        </div>
    )

    const headerRoutes = (
        <div className="table-header">
            <h5 className="p-m-2">Lista de rotas</h5>
        </div>
    )

    const licensePlateListBodyTemplate = (routeResponse: routeResponse) => {
        return <>{routeResponse.licensePlate}</>;
    }

    const cameraNameListBodyTemplate = (routeResponse: routeResponse) => {
        return <>{routeResponse.cameraName}</>;
    }

    const latitudeListBodyTemplate = (routeResponse: routeResponse) => {
        return <>{routeResponse.latitude}</>;
    }

    const longitudeListBodyTemplate = (routeResponse: routeResponse) => {
        return <>{routeResponse.longitude}</>;
    }

    const detectionTimeListBodyTemplate = (routeResponse: routeResponse) => {
        return <>{routeResponse.detectionTime}</>;
    }

    const licensePlateBodyTemplate = (listRouteResponse: listRouteResponse) => {
        return <>{listRouteResponse.licensePlate}</>;
    }

    const qtdBodyTemplate = (listRouteResponse: listRouteResponse) => {
        return <>{listRouteResponse.vehicleRouteResponses.length}</>;
    }

    const actionMapBodyTemplate = (rowData: listRouteResponse) => {
        return (
            <div className="actions my-2 xl:offset-md-5">
                <Button
                    icon="pi pi-external-link"
                    className="p-button-rounded p-button-secondary"
                    onClick={() => {
                        props.selectRoute(rowData.vehicleRouteResponses)
                        props.setIsRoutePopupOpen(false)
                    }}
                />
            </div>
        );
    };

    const actionListBodyTemplate = (rowData: listRouteResponse) => {
        return (
            <div className="actions my-2 xl:offset-md-5">
                {rowData.vehicleRouteResponses.length > 1 && (
                    <Button
                        icon="pi pi-chevron-right"
                        className="p-button-rounded p-button-success"
                        onClick={() => toggleRoutePopup(rowData)}
                    />
                )}
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={props.globalFilter || ""}
                            onInput={(e: any) => {
                                props.setGlobalFilter(e.target.value)}}
                            placeholder="Procurar..."
                        />
                    </span>
                </React.Fragment>
            </>
        );
    }

    const formatDateTime = (input: Date): string => {
        return input.toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    const formatDateTime2String = (input: string): string => {
        return input
            .replace(/\//g, '') // Remove as barras
            .replace(', ', '_') // Substitui ", " por "_"
            .replace(/:/g, ''); // Remove os dois-pontos
    }

    const exportToCSV = () => {
        if (!(routes.length > 1)) { return }
        const formattedDate = formatDateTime(new Date())

        function formatDateTime2String(input: string): string {
            return input
                .replace(/\//g, '') // Remove as barras
                .replace(', ', '_') // Substitui ", " por "_"
                .replace(/:/g, ''); // Remove os dois-pontos
        }

        const formattedDateTime = formatDateTime2String(formattedDate);
        // Cabeçalhos do CSV
        const headers = ["Placa", "Câmera", "Latitude", "Longitude", "Data"];

        // Constrói as linhas do CSV
        const csvRows = routes.map((row) => [
            row.licensePlate,
            row.cameraName,
            row.latitude,
            row.longitude,
            row.detectionTime,
        ]);

        // Adiciona os cabeçalhos no início do CSV
        csvRows.unshift(headers);

        // Adiciona o BOM e transforma as linhas em strings CSV, separadas por ponto e vírgula
        const csvContent = "\uFEFF" + csvRows.map((row) => row.join(";")).join("\n");

        // Cria um blob para download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        // Cria um link de download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${props.selectedPlate}_${formattedDateTime}_datatable.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToPDF = () => {
        if (!(routes.length > 1)) { return }
        const doc = new jsPDF();
        const tableColumn = ["Placa", "Camera", "Latitude", "Longitude", "Data"]; // Cabeçalhos
        const tableRows = routes.map((row) => [
            row.licensePlate,
            row.cameraName,
            row.latitude,
            row.longitude,
            row.detectionTime,
        ]); // Dados das linhas


        const formattedDate = formatDateTime(new Date())

        const formattedDateTime = formatDateTime2String(formattedDate);

        // Adicione o título centralizado
        const pageWidth = doc.internal.pageSize.getWidth(); // Largura da página
        const title = `Rotas para o veículo de placa ${props.selectedPlate}`;
        const textWidth = doc.getTextWidth(title); // Largura do texto
        const textX = (pageWidth - textWidth) / 2; // Calcula a posição X para centralizar

        doc.setFontSize(14);
        doc.text(title, textX, 10); // Renderiza o texto centralizado

        // Adicione a imagem ao PDF (caminho da imagem importado)
        const imgWidth = 50; // Largura da imagem no PDF
        const imgHeight = 15; // Altura proporcional ao tamanho original
        doc.addImage(logo, "PNG", 15, 20, imgWidth, imgHeight);

        // Configura a fonte e o tamanho para o texto da data
        doc.setFontSize(10);
        doc.text(
            `Período: ${props.initialDate != null ? formatDateTime(props.initialDate) : "não setado"
            } - ${props.finalDate != null ? formatDateTime(props.finalDate) : formattedDate
            }`,
            pageWidth - 15,
            25,
            { align: 'right' }
        );


        // Configura a fonte e o tamanho para o texto da data
        doc.setFontSize(10);
        doc.text(`Relatório gerado em ${formattedDate}`, pageWidth - 15, 35, { align: 'right' });

        // Cria a tabela
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 40,
        });

        // Salva o PDF
        doc.save(`${props.selectedPlate}_${formattedDateTime}_datatable.pdf`);
    };

    const leftToolbarListTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    style={{ color: "#fcad03", fontSize: "1.2rem" }}
                    label="Gerar rota no mapa"
                    icon="pi pi-file-pdf"
                    className="p-button-text"
                    onClick={() => {
                        if (routes) {
                            props.selectRoute(routes); // Passa a rota selecionada
                            setIsListRoutePopupOpen(false)
                            props.setIsRoutePopupOpen(false)
                        }
                    }}
                />
            </React.Fragment>
        );
    };

    const rightToolbarListTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Gerar PDF" icon="pi pi-file-pdf" className="p-button-text" onClick={(e) => exportToPDF()} />
                <Button label="Gerar CSV" icon="pi pi-file-pdf" className="p-button-text" onClick={(e) => exportToCSV()} />
            </React.Fragment>
        )

    }

    const toggleRoutePopup = (rowData: listRouteResponse) => {
        setSelectedRoute(rowData)
        setRoutes(rowData.vehicleRouteResponses)
        setIsListRoutePopupOpen(!isListRoutePopupOpen);
    };

    return (
        <React.Fragment>
            {!isListRoutePopupOpen && <>
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                <DataTable
                    value={props.listRoutes}
                    dataKey="listrouteId" // Certifique-se de que esta propriedade é única para cada item
                    selectionMode="single" // Modo de seleção única
                    selection={selectedRoute} // Estado para o item selecionado
                    onSelectionChange={(e: any) => setSelectedRoute(e.value)} // Atualiza o estado ao selecionar
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} lista de rotas"
                    globalFilter={props.globalFilter || ""}
                    emptyMessage="Nenhuma lista de rota encontrada."
                    header={header}
                    loading={props.loading}>
                    <Column field="licensePlate" header="Placa" sortable body={licensePlateBodyTemplate}></Column>
                    <Column field="qtd" header="Quantidade" sortable body={qtdBodyTemplate}></Column>
                    <Column header="Exibir lista" body={actionListBodyTemplate}></Column>
                    <Column header="Rota no mapa" body={actionMapBodyTemplate}></Column>
                </DataTable>
            </>}

            <Dialog
                visible={isListRoutePopupOpen}
                onHide={() => setIsListRoutePopupOpen(false)}
            >
                <Toolbar className="p-mb-4" left={leftToolbarListTemplate} right={rightToolbarListTemplate}></Toolbar>
                <DataTable
                    value={routes}
                    dataKey="licensePlate" // Certifique-se de que esta propriedade é única para cada item
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-list datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} rotas"
                    emptyMessage="Nenhuma rota encontrada."
                    header={headerRoutes}
                >
                    <Column field="licensePlate" header="Placa" sortable body={licensePlateListBodyTemplate}></Column>
                    <Column field="cameraName" header="Camera" sortable body={cameraNameListBodyTemplate}></Column>
                    <Column field="latitude" header="Latitude" sortable body={latitudeListBodyTemplate}></Column>
                    <Column field="longitude" header="Longitude" sortable body={longitudeListBodyTemplate}></Column>
                    <Column field="detectionTime" header="Data" sortable body={detectionTimeListBodyTemplate}></Column>

                </DataTable>
            </Dialog>
        </React.Fragment>
    )
}
