// Componente que centraliza o mapa dinamicamente
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { DynamicMapCenterProps } from '../../types/types';

export const DynamicMapCenter: React.FC<DynamicMapCenterProps> = (props: DynamicMapCenterProps) => {
    const map = useMap();

    useEffect(() => {
        if (props.latitude && props.longitude) {
            map.setView([props.latitude, props.longitude], props.zoom);
        }
    }, [props.latitude, props.longitude, props.zoom, map]);

    return null;
};
