import React, { useState, useCallback } from "react";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { VehicleBehavior, VehicleBehaviorListDataTableProps } from "../types/types";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import jsPDF from "jspdf";
import logo from "../../../wwroot/img/olho-vivo-logo.png";

export const VehicleBehaviorListDataTable = React.memo((props: VehicleBehaviorListDataTableProps) => {
    // Estado para armazenar a seleção dos itens
    const [selectedVehicles, setSelectedVehicles] = useState<VehicleBehavior[]>([]);

    // Memoiza o header para evitar re-renderizações desnecessárias
    const header = useCallback(() => (
        <div className="table-header">
            <h5 className="p-m-2">Dados</h5>
        </div>
    ), []);

    // Memoiza as funções de template das colunas
    const licenseBodyTemplate = useCallback((vehicle: VehicleBehavior) => {
        return <>{vehicle.licensePlate}</>;
    }, []);

    const cameraNameBodyTemplate = useCallback((vehicle: VehicleBehavior) => {
        return <>{vehicle.camera.cameraName}</>;
    }, []);

    const locationNameBodyTemplate = useCallback((vehicle: VehicleBehavior) => {
        return (
            <>
                {`${vehicle.location.locationName}/${vehicle.location.stateProvince}`}
            </>
        );
    }, []);


    const dayOfWeekBodyTemplate = useCallback((vehicle: VehicleBehavior) => {
        return <>{formatDayOfWeek(vehicle.dayOfWeek)}</>;
    }, []);


    const hourBodyTemplate = useCallback((vehicle: VehicleBehavior) => {
        return <>{formatHour(vehicle.hour)}</>;
    }, []);

    const countBodyTemplate = useCallback((vehicle: VehicleBehavior) => {
        return <>{vehicle.count}</>;
    }, []);


    // Funções de formatação de data
    const formatDateTime = useCallback((input: Date): string => {
        return input.toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    }, []);

    const formatDateTime2String = useCallback((input: string): string => {
        return input
            .replace(/\//g, '') // Remove as barras
            .replace(', ', '_') // Substitui ", " por "_"
            .replace(/:/g, ''); // Remove os dois-pontos
    }, []);

    const formatDayOfWeek = (dayOfWeek: number): string => {
        const dayOfWeekNames = [
            "domingo",
            "segunda-feira",
            "terça-feira",
            "quarta-feira",
            "quinta-feira",
            "sexta-feira",
            "sábado"
        ];
        return dayOfWeekNames[dayOfWeek] || "";
    };

    const formatHour = (hour: number): string => {
        const hourDate = new Date();
        hourDate.setHours(hour, 0, 0, 0); // Define hora, minutos, segundos e milissegundos
        return hourDate.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });
    };

    const sortData = (data: VehicleBehavior[]): VehicleBehavior[] => {
        return [...data].sort((a, b) => {
            // Ordenação primária: count (decrescente)
            if (b.count !== a.count) {
                return b.count - a.count;
            }

            // Ordenação secundária: hour (crescente)
            if (a.hour !== b.hour) {
                return a.hour - b.hour;
            }

            // Ordenação terciária: cameraName (crescente)
            return a.camera.cameraName.localeCompare(b.camera.cameraName);
        });
    };



    // Função para exportar para CSV
    const exportToCSV = useCallback(() => {
        if (!(props.vehicleDataBehavior.length > 1)) { return; }

        const sortedData = sortData(props.vehicleDataBehavior); // Ordena os dados

        const formattedDate = formatDateTime(new Date());
        const formattedDateTime = formatDateTime2String(formattedDate);

        // Cabeçalhos do CSV
        const headers = ["Placa", "Câmera", "Localidade", "Estado", "Semana", "Hora", "Contagem"];

        // Constrói as linhas do CSV
        const csvRows = sortedData.map((row) => [
            row.licensePlate,
            row.camera.cameraName,
            row.location.locationName,
            row.location.stateProvince,
            formatDayOfWeek(row.dayOfWeek),
            formatHour(row.hour),
            row.count
        ]);

        // Adiciona os cabeçalhos no início do CSV
        csvRows.unshift(headers);

        // Adiciona o BOM e transforma as linhas em strings CSV, separadas por ponto e vírgula
        const csvContent = "\uFEFF" + csvRows.map((row) => row.join(";")).join("\n");

        // Cria um blob para download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        // Cria um link de download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${props.selectedPlate}_${formattedDateTime}_datatable.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [props.vehicleDataBehavior, props.selectedPlate, formatDateTime, formatDateTime2String]);

    // Função para exportar para PDF
    const exportToPDF = useCallback(() => {
        if (!(props.vehicleDataBehavior.length > 1)) { return; }
        const sortedData = sortData(props.vehicleDataBehavior); // Ordena os dados
        const doc = new jsPDF();
        const tableColumn = ["Placa", "Câmera", "Localidade", "Estado", "Semana", "Hora", "Contagem"];
        const tableRows = sortedData.map((row) => [
            row.licensePlate,
            row.camera.cameraName,
            row.location.locationName,
            row.location.stateProvince,
            formatDayOfWeek(row.dayOfWeek),
            formatHour(row.hour),
            row.count
        ]);

        const formattedDate = formatDateTime(new Date());
        const formattedDateTime = formatDateTime2String(formattedDate);

        // Adicione o título centralizado
        const pageWidth = doc.internal.pageSize.getWidth();
        const title = `Dados para o veículo de placa ${props.selectedPlate}`;
        const textWidth = doc.getTextWidth(title);
        const textX = (pageWidth - textWidth) / 2;

        doc.setFontSize(14);
        doc.text(title, textX, 10);

        // Adicione a imagem ao PDF
        const imgWidth = 50;
        const imgHeight = 15;
        doc.addImage(logo, "PNG", 15, 20, imgWidth, imgHeight);

        // Configura a fonte e o tamanho para o texto da data
        doc.setFontSize(10);
        doc.text(
            `Período: ${props.initialDate != null ? formatDateTime(props.initialDate) : "não setado"
            } - ${props.finalDate != null ? formatDateTime(props.finalDate) : formattedDate
            }`,
            pageWidth - 15,
            25,
            { align: 'right' }
        );

        // Configura a fonte e o tamanho para o texto da data
        doc.text(`Relatório gerado em ${formattedDate}`, pageWidth - 15, 35, { align: 'right' });

        // Cria a tabela
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 40,
        });

        // Salva o PDF
        doc.save(`${props.selectedPlate}_${formattedDateTime}_datatable.pdf`);
    }, [props.vehicleDataBehavior, props.selectedPlate, props.initialDate, props.finalDate, formatDateTime, formatDateTime2String]);

    // Memoiza os templates da barra de ferramentas
    const leftToolbarTemplate = useCallback(() => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={props.globalFilter || ""}
                        onInput={(e: any) => {
                            const newValue = e.target.value;
                            if (props.globalFilter !== newValue) {
                                props.setGlobalFilter(newValue);
                            }
                        }}
                        placeholder="Procurar..."
                    />
                </span>
            </React.Fragment>
        );
    }, [props.globalFilter, props.setGlobalFilter]);

    const rightToolbarTemplate = useCallback(() => {
        return (
            <React.Fragment>
                <Button label="Gerar PDF" icon="pi pi-file-pdf" className="p-button-text" onClick={exportToPDF} />
                <Button label="Gerar CSV" icon="pi pi-file-pdf" className="p-button-text" onClick={exportToCSV} />
            </React.Fragment>
        );
    }, [exportToPDF, exportToCSV]);



    return (
        <React.Fragment>
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            {/* Tabela de Resultados */}
            <DataTable
                value={props.vehicleDataBehavior}
                paginator
                rows={10}
                loading={props.loading}
                selection={selectedVehicles}
                onSelectionChange={(e: any) => setSelectedVehicles(e.value)}
                selectionMode="multiple"
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} dados localizados"
                globalFilter={props.globalFilter || ""}
                emptyMessage="Nenhum veículo encontrado."
                header={header()}
                multiSortMeta={[
                    { field: "count", order: -1 }, // Ordenação primária: "count" decrescente
                    { field: "hour", order: 1 },  // Ordenação secundária: "hour" crescente
                    { field: "cameraName", order: 1 },  // Ordenação terciária: "cameraName" crescente
                ]}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                <Column field="licensePlate" header="Placa" body={licenseBodyTemplate}></Column>
                <Column field="cameraName" header="Camera" sortable body={cameraNameBodyTemplate}></Column>
                <Column field="locationName" header="Localidade" body={locationNameBodyTemplate}></Column>
                <Column field="dayOfWeek" header="Semana" sortable body={dayOfWeekBodyTemplate}></Column>
                <Column field="hour" header="Hora" sortable body={hourBodyTemplate}></Column>
                <Column field="count" header="Contagem" sortable body={countBodyTemplate}></Column>

            </DataTable>
        </React.Fragment>
    );
});
