import React, { useEffect } from "react"
import AlarmeCustomizado from "../../../wwroot/audios/alarme_customizado.wav"
import BlackList from "../../../wwroot/audios/black_list.wav"
import Sirene from "../../../wwroot/audios/Sirene.wav"
import ModoComboio from "../../../wwroot/audios/modo_comboio.wav"
import ClonedVehicle from "../../../wwroot/audios/cloned-vehicle.wav"
import AnaliseMotoqueiros from "../../../wwroot/audios/analise_motoqueiros.wav"
import DeslocamentoEspecial from "../../../wwroot/audios/deslocamento_especial.wav"

function AudioPlayer(props) {
    var audioFile;

    const audioRef = React.createRef();
    useEffect(() => {
        if (props.play) {
            audioRef.current.play();
        }
    })

    audioFile = SelectAudioFile(props.soundName);

    return <audio src={audioFile} ref={audioRef} />;
}

function SelectAudioFile(sound_name) {
    switch (sound_name) {
        case 'alarme_customizado':
            return AlarmeCustomizado;
        case 'black_list':
            return BlackList;
        case 'deslocamento_especial':
            return DeslocamentoEspecial;
        case 'analise_motoqueiros':
            return AnaliseMotoqueiros;
        case 'modo_comboio':
            return ModoComboio;
        case 'cloned_vehicle':
            return ClonedVehicle;
        default:
            return Sirene;
    }
}

export default AudioPlayer;
