import React from 'react';
import { ClonedVehicleConfiguration } from '../../types/types';
import { Button } from 'primereact/button';
import { WhatsappGroup } from '../../../../components/WhatsappGroup/whatsapp-group';
import { Fieldset } from 'primereact/fieldset';

export interface WhatsappClonedVehicleConfigProps {
    onSaveConfig: () => void,
    clonedVehicleConfig: ClonedVehicleConfiguration,
    setClonedVehicleConfig: (object: any) => void
}
export const WhatsappClonedVehicleConfig = (props: WhatsappClonedVehicleConfigProps) => {
    const header = <>
        <span className="titulo-categoria">
            <i className='pi pi-whatsapp mr-2'></i>
            Configuração Notificação via Whatsapp
        </span>
    </>;

    return (
        <>
            <Fieldset legend={header} className='mb-4'>
                <WhatsappGroup
                    selectedLocation={props.clonedVehicleConfig.locationId}
                    selectedGroupId={props.clonedVehicleConfig.whatsappGroupId}
                    object={props.clonedVehicleConfig}
                    objectUpdate={props.setClonedVehicleConfig}
                />
                <Button className="mt-4"
                    label="SALVAR CONFIGURAÇÃO"
                    onClick={(e) => {
                        props.onSaveConfig()
                    }}
                />
            </Fieldset>

        </>
    )
}
