import React, { useState } from 'react';
import { PageHeader } from '../../../../views/page-header';
import { InputText } from 'primereact/inputtext';
import '../../styles/vehicle-route.css';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { VehicleDetectionServices } from '../../../../services/vehicle-detection-services';
import { VehicleRouteProps, listRouteResponse } from '../../types/types';
import { RouteListDataTable } from '../../data-tables/route-list-data-table';

export const VehicleRoute: React.FC<VehicleRouteProps> = (props: VehicleRouteProps) => {

    const _vehicleDetectionServices = new VehicleDetectionServices();

    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedPlate, setSelectedPlate] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [initialDate, setInitialDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);

    const [listRoutes, setListRoutes] = useState<listRouteResponse[]>([]);


    const searchRoute = async () => {
        try {
            setLoading(true);
            const response = await _vehicleDetectionServices.getVehicleRoute(props.selectedRouteLocation, selectedPlate, initialDate, finalDate);
            setListRoutes(response.data);
        } catch (error) {
            console.error("Erro ao buscar rotas", error);
        }
        setLoading(false);
    };

    const isOlderDateSelectedFinal = (selectedDate: Date): boolean => {
        if (finalDate) {
            let dateDay: number = selectedDate.getDate();
            let finalDay: number = finalDate.getDate();

            let dateMonth: number = selectedDate.getMonth();
            let finalMonth: number = finalDate.getMonth();

            let dateYear: number = selectedDate.getFullYear();
            let finalYear: number = finalDate.getFullYear();

            return dateDay > finalDay || dateMonth > finalMonth || dateYear > finalYear;
        } else {
            return true;
        }
    };

    const isNewerDateSelectedInitial = (selectedDate: Date): boolean => {
        if (initialDate != null) {
            let dateDay: number = selectedDate.getDate();
            let initialDay: number = initialDate.getDate();

            let dateMonth: number = selectedDate.getMonth();
            let initialMonth: number = initialDate.getMonth();

            let dateYear: number = selectedDate.getFullYear();
            let initialYear: number = initialDate.getFullYear();
            return dateDay < initialDay || dateMonth < initialMonth || dateYear < initialYear;
        } else {
            return false;
        }
    };


    return (
        <React.Fragment>

            <div className="p-fluid p-grid vehicle-route">
                <div className="col-12">
                    <PageHeader
                        title="ROTA DE VEÍCULOS"
                        icon="pi pi-car"
                    />
                    <div className="categoria">
                        <div className="grid">
                            {/* Seletor de Localização */}
                            <div className="categoria col-6">
                                <div className="p-inputgroup">
                                    <strong className="p-inputgroup-addon">
                                        <i className="pi pi-map-marker"></i>
                                    </strong>
                                    <Dropdown
                                        value={props.selectedRouteLocation !== undefined ? props.selectedRouteLocation : 0}
                                        disabled={props.loading}
                                        onChange={props.onSelectedRouteLocation}
                                        placeholder="Selecione uma cidade..."
                                        optionLabel="description"
                                        optionValue="id"
                                        options={props.locations}
                                        emptyMessage="Nenhuma localidade encontrada"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="categoria mb-2">
                                    <strong>Placa *</strong>
                                    <InputText
                                        id="plate"
                                        value={selectedPlate}  // Estado para a placa
                                        onChange={(e) => setSelectedPlate(e.target.value)}  // Atualiza o valor da placa
                                        placeholder="Digite a placa do veículo"
                                    />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="categoria mb-2">
                                    <strong>Data de Início</strong>
                                    <Calendar
                                        id="initialDate"
                                        value={initialDate}
                                        onChange={(e: any) => setInitialDate(e.value)}
                                        disabled={loading || loading || loading}
                                        showIcon
                                        placeholder="Selecione a Data de Início menor que a Data de Fim"
                                        dateFormat="dd/mm/yy"
                                        className={classNames({ "p-invalid": initialDate && finalDate != null && isOlderDateSelectedFinal(finalDate) })}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="categoria mb-2">
                                    <strong>Data de Fim</strong>
                                    <Calendar
                                        id="finalDate"
                                        value={finalDate}
                                        onChange={(e: any) => setFinalDate(e.value)}
                                        disabled={loading || loading || loading}
                                        showIcon
                                        placeholder="Selecione a Data de Fim maior que a Data de Início"
                                        dateFormat="dd/mm/yy"
                                        className={classNames({ "p-invalid": initialDate != null && isNewerDateSelectedInitial(initialDate) })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        label="Buscar Rotas"
                        icon="pi pi-search"
                        onClick={searchRoute}
                        disabled={loading || loading || loading}
                        loading={loading}
                    />
                    <div className="card">
                        <RouteListDataTable
                            setIsRoutePopupOpen={props.setIsRoutePopupOpen}
                            initialDate={initialDate}
                            finalDate={finalDate}
                            selectedPlate={selectedPlate}
                            setGlobalFilter={setGlobalFilter}
                            globalFilter={globalFilter}
                            listRoutes={listRoutes}
                            selectRoute={props.onSelectRoute}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment >
    );
};
