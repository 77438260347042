import { Dialog } from 'primereact/dialog';
import React from 'react';
import { ClonedVehicleAlertResponse } from '../../../../models/types';
import '../../styles/cloned-vehicle-alert-dialog.scss';
import { VehicleTypeIcon } from '../../../../components/VehicleTypeIcon/vehicle-type-icon';
import { ImageViewer } from '../../../../components/ImageViewer/image-viewer';

export interface ClonedVehicleAlertDialogProps {
    clonedVehicleAlertDialog: boolean,
    clonedVehicleAlert: ClonedVehicleAlertResponse,
    setClonedVehicleAlertDialog: (state: boolean) => void
}

export const ClonedVehicleAlertDialog = (props: ClonedVehicleAlertDialogProps) => {
    return (
        <Dialog visible={props.clonedVehicleAlertDialog} className='cloned-vehicle-dialog' resizable={false} header='ALERTA DE VEÍCULO CLONADO DETECTADO' onHide={() => props.setClonedVehicleAlertDialog(false)}>
            <div className="p-2">
                <div className='grid' style={{ margin: '0px', border: '0.5px solid var(--text-color)', borderRadius: '10px' }}>
                    <div className='col-12'>
                        <div className='title-message justify-content-center flex'>
                            <span>
                                <i className="pi pi-camera mr-2" />
                                {props.clonedVehicleAlert.cameraName}
                            </span>
                        </div>
                        <div className='col-12'>
                            <div className='flex justify-content-center'>
                                <i className="pi pi-map" />
                                <span className='ml-2 cloned-vehicle-text'>{props.clonedVehicleAlert.cameraAddress}</span>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='flex col-6'>
                        <div className='grid col-12'>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className="flex justify-content-center">
                                            <ImageViewer
                                                alt={props.clonedVehicleAlert.firstVehiclePlate}
                                                src={props.clonedVehicleAlert.firstVehicleImageUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    border: '1px solid var(--text-color)',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-tag" />
                                            <span className='ml-2 cloned-vehicle-text'>{props.clonedVehicleAlert.firstVehiclePlate}</span>
                                            <span className="mr-2 ml-2"></span>
                                            <VehicleTypeIcon classes="mr-2" size="lg" val={props.clonedVehicleAlert.firstVehicleType} typeInput="number" />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-clock" />
                                            <span className='ml-2 cloned-vehicle-text'>{props.clonedVehicleAlert.firstEventDateTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex col-6'>
                        <div className='grid col-12'>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className="flex justify-content-center">
                                            <ImageViewer
                                                alt={props.clonedVehicleAlert.secondVehiclePlate}
                                                src={props.clonedVehicleAlert.secondVehicleImageUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    border: '1px solid var(--text-color)',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-tag" />
                                            <span className='ml-2 cloned-vehicle-text'>{props.clonedVehicleAlert.secondVehiclePlate}</span>
                                            <span className="mr-2 ml-2"></span>
                                            <VehicleTypeIcon classes="mr-2" size="lg" val={props.clonedVehicleAlert.secondVehicleType} typeInput="number" />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-clock" />
                                            <span className='ml-2 cloned-vehicle-text'>{props.clonedVehicleAlert.secondEventDateTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
