import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect'; // Importa o MultiSelect
import { Fieldset } from 'primereact/fieldset';
import React from 'react';
import { DropdownFiltersProps } from '../../types/types';

// Definimos as opções de tipos de veículos
const vehicleTypesOptions = [
    { label: 'Carro', value: 'quantityCar' },
    { label: 'Moto', value: 'quantityMotorBike' },
    { label: 'Caminhão', value: 'quantityTruck' },
    { label: 'Ônibus', value: 'quantityBus' },
    { label: 'Pickup', value: 'quantityPickup' },
    { label: 'Outro', value: 'quantityOther' },
];

export const DropdownFilters = (props: DropdownFiltersProps) => {

    const isOlderDateSelected = (selectedDate: Date): boolean => {
        const dateDay = selectedDate.getDate();
        const todayDay = props.today.getDate();
        const dateMonth = selectedDate.getMonth();
        const todayMonth = props.today.getMonth();
        const dateYear = selectedDate.getFullYear();
        const todayYear = props.today.getFullYear();

        return dateDay < todayDay || dateMonth < todayMonth || dateYear < todayYear;
    };

    const headerFilterFieldset = (
        <span className="titulo-categoria">
            <i className='pi pi-filter mr-2'></i>
            Filtros
        </span>
    );

    return (
        <React.Fragment>
            <Fieldset legend={headerFilterFieldset}>
                <div className="p-grid p-fluid">
                    {/* Seletor de Data */}
                    <div className="categoria col-12 lg:col-12 xl:col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-calendar"></i>
                            </span>
                            <Calendar
                                value={props.date}
                                maxDate={props.today}
                                onChange={(e: any) => props.onSelectedDate(e)}
                                dateFormat="dd/mm/yy"
                                className={classNames({ "p-invalid": isOlderDateSelected(props.date) })}
                            />
                        </div>
                    </div>

                    {/* Seletor de Localização */}
                    <div className="categoria col-12 lg:col-12 xl:col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                value={props.selectedLocation !== undefined ? props.selectedLocation : 0}
                                disabled={props.loadingLocations}
                                onChange={props.onSelectedLocation}
                                placeholder="Selecione uma cidade..."
                                optionLabel="description"
                                optionValue="id"
                                options={props.locations}
                                emptyMessage="Nenhuma localidade encontrada"
                            />
                        </div>
                    </div>

                    {/* Seletor de Câmera */}
                    <div className="categoria col-12 lg:col-12 xl:col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera"></i>
                            </span>
                            <Dropdown
                                value={props.selectedCamera !== undefined ? props.selectedCamera : 0}
                                placeholder="Selecione uma câmera..."
                                optionLabel="description"
                                optionValue="id"
                                disabled={props.selectedLocation === 0}
                                onChange={(e: any) => props.onSelectedCamera(e)}
                                options={props.cameras}
                            />
                        </div>
                    </div>

                    {/* Seletor de Tipo de Veículo (Usando MultiSelect) */}
                    <div className="categoria col-12 lg:col-12 xl:col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-car"></i>
                            </span>
                            <MultiSelect
                                value={props.selectedVehicleTypes}  // Agora utiliza o MultiSelect
                                placeholder="Selecione os tipos de veículos..."
                                optionLabel="label"
                                optionValue="value"
                                options={vehicleTypesOptions}
                                onChange={(e: any) => props.onSelectedVehicleTypes(e.value)}
                                display="chip"  // Exibe os itens selecionados como chips
                            />
                        </div>
                    </div>
                </div>
            </Fieldset>
        </React.Fragment>
    );
};
