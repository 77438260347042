import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Fieldset } from "primereact/fieldset";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { environment } from "../../../configuration/environment";
import { DropdownInfo } from "../../../models/types";
import { BehavioralConvoyServices } from "../../../services/behavioral-convoy-services";
import { CameraServices } from "../../../services/camera-services";
import { ConvoyServices } from "../../../services/convoy-services";
import { FeatureServices } from "../../../services/feature-services";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { CameraClonedVehicleResponse } from "../../Camera/types/types";
import { ConfigurationsResponse, ClonedVehicleConfiguration } from "../types/types";
import "../styles/cloned-vehicle-camera.scss";
// import { BehavioralConvoyConfig } from "./components/behavioral-convoy-config";
import { LocationConvoyHeader } from "./components/location-convoy-header";
// import { TimeConvoyConfig } from "./components/time-convoy-config";
import { WhatsappClonedVehicleConfig } from "./components/whatsapp-cloned-vehicle-config";

const locationConfig: ClonedVehicleConfiguration = {
    locationId: 5,
    startTime: new Date(),
    stopTime: new Date(),
    whatsappGroupId: null
};
export const ClonedVehicleData = () => {
    const _authServices = new AuthServices();
    const _toastServices = new ToastServices();
    const _cameraServices = new CameraServices();
    const _convoyServices = new ConvoyServices();
    const _behavioralConvoyServices = new BehavioralConvoyServices();
    const _featureServices = new FeatureServices();

    const [loading, setLoading] = useState<boolean>(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(true);
    const [cameraList, setCameraList] = useState<CameraClonedVehicleResponse[]>([]);
    const [clonedVehicleConfig, setClonedVehicleConfig] = useState<ClonedVehicleConfiguration>(locationConfig);
    // const [selectedBehavioralConvoy, setSelectedBehavioralConvoy] = useState<number[]>([]);
    const [configurations, setConfigurations] = useState<ConfigurationsResponse[]>([]);

    _authServices.HandleAppAuth();

    const isModelValid = selectedLocation !== 0 && clonedVehicleConfig.startTime && clonedVehicleConfig.stopTime;
    useEffect(() => {
        setLoading(true);
        _featureServices
            .getLocationsByFeature("CONVOY-ALARM")
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            })
            .catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (selectedLocation !== 0) {
            getLocationConvoyTime(selectedLocation);
            getCameraClonedVehicleConfiguration(selectedLocation);
            getConfigurations();
            getLocationBehavioralConvoy(selectedLocation);
        }
    }, [selectedLocation])

    const getFilteredCameraList = () => {
        if (globalFilter) {
            return cameraList.filter((camera) => camera.camera.toLowerCase().includes(globalFilter.toLowerCase()));
        }

        return cameraList;
    }

    const onSelectedUF = (e: { value: any }) => {
        setLoading(true);
        setSelectedLocation(e.value);
        setLoadingCameras(true);
        getLocationBehavioralConvoy(e.value);
    }

    const getLocationConvoyTime = (selectedLocation: number) => {
        _convoyServices
            .getLocationConvoyTime(selectedLocation)
            .then((data) => {
                var locationConvoyTime: ClonedVehicleConfiguration = {
                    locationId: data.data.locationId,
                    startTime: new Date(data.data.startTime),
                    stopTime: new Date(data.data.stopTime),
                    whatsappGroupId: data.data.whatsappGroupId
                };

                setClonedVehicleConfig(locationConvoyTime);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    }

    const getLocationBehavioralConvoy = (selectedLocation: number) => {
        _behavioralConvoyServices
            .getBehavioralConvoy(selectedLocation)
            .then((data) => {
                // setSelectedBehavioralConvoy(data.data.vehicleTypesIds);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    }

    const getConfigurations = () => {
        return _behavioralConvoyServices
            .getConfigurations()
            .then((data) => {
                setConfigurations(data.data);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    }

    const getCameraClonedVehicleConfiguration = (selectedLocation: number) => {
        setLoadingCameras(true);

        _cameraServices
            .getCamerasIntelligenceConfiguration(selectedLocation)
            .then((data) => {
                setCameraList(data.data);
                setLoadingCameras(false);
                setLoading(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    }

    const onChangedClonedVehicle = (cameraClonedVehicle: CameraClonedVehicleResponse) => {
        _cameraServices
            .setCameraIntelligenceConfiguration(cameraClonedVehicle.cameraId, !cameraClonedVehicle.indClonedVehicle, null)
            .then((data) => {
                _toastServices.showSuccesMessage("Camera " + cameraClonedVehicle.camera + " atualizada");
                getCameraClonedVehicleConfiguration(selectedLocation);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
            });
    }


    // const getImageSrc = (cameraId: number, locationId: number): string => {
    //     return environment().baseUrl + environment().version + `/dashboard/snapshot/${locationId}/${cameraId}`;
    // }

    const getToggleButton = (cameraClonedVehicle: CameraClonedVehicleResponse) => {
        let convoyEnabled: boolean = cameraClonedVehicle.indClonedVehicle;
        let label: string = "DESLIGADO";
        let icon: string = "pi pi-times";
        let severity: any = "warning";

        if (convoyEnabled === true) {
            label = "LIGADO";
            icon = "pi pi-check";
            severity = "success";
        }

        return <Button severity={severity} label={label} icon={icon} onClick={(e) => onChangedClonedVehicle(cameraClonedVehicle)} className="w-9rem" />;
    }


    const renderGridItem = (cameraClonedVehicle: CameraClonedVehicleResponse) => {
        return (
            <>
                <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-camera"></i>
                                <span className="product-category">{cameraClonedVehicle.camera}</span>
                            </div>
                        </div>
                        <div className="flex justify-content-between">

                            <div>
                                <span className="text-2xl font-semibold">Modo Alerta Veículo Clonado</span>
                                <div className="col-12">{getToggleButton(cameraClonedVehicle)}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }

    const itemTemplate = (cameraClonedVehicle: CameraClonedVehicleResponse) => {
        if (!cameraClonedVehicle) {
            return;
        }

        return renderGridItem(cameraClonedVehicle);
    }

    const renderSkeletonDataScroller = () => {
        return (
            <>
                <div className="flex justify-content-center">
                    <div className="product-item mr-2">
                        <Skeleton shape="rectangle" width="520px" height="113px" />
                    </div>
                    <div className="product-item mr-2">
                        <Skeleton shape="rectangle" width="520px" height="113px" />
                    </div>
                    <div className="product-item mr-2">
                        <Skeleton shape="rectangle" width="520px" height="113px" />
                    </div>
                </div>
            </>
        );
    }

    const onSaveConfig = () => {
        if (isModelValid) {
            setLoading(true);
            _convoyServices
                .updateConvoyTime(selectedLocation, clonedVehicleConfig.startTime, clonedVehicleConfig.stopTime,
                    clonedVehicleConfig.whatsappGroupId)
                .then((data) => {
                    setLoading(false);
                    _toastServices.showSuccesMessage("Horário de modo comboio atualizado com sucesso");
                })
                .catch((error) => {
                    _toastServices.showErrorRequestMessage(error);
                });
        }
    }


    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CONFIGURAÇÃO ALERTA VEÍCULO CLONADO" icon="pi pi-chart-bar" />
                <div className="card">
                    <LocationConvoyHeader
                        globalFilter={globalFilter}
                        loading={loading}
                        locations={locations}
                        onSelectedUF={onSelectedUF}
                        selectedLocation={selectedLocation}
                        setGlobalFilter={setGlobalFilter}
                    />
                    {selectedLocation !== 0 && !loading && (
                        <div className="grid">
                            <div className='col-12'>
                                <Fieldset legend="MODO ALERTA VEÍCULO CLONADO" toggleable>
                                    <p className="m-0 mb-1">
                                        {/* <strong>✦ MODO ALERTA VEÍCULO CLONADO:</strong> */}
                                        <p className="m-2">Caso uma câmera com a opção do Modo Alerta de veículo Clonado ativada registre a passagem de um veículo **DE QUALQUER TIPO** com placa tal e que esta placa tal esteja em outro veículo que passou em outra camera também com o modo ativado, após cálculos matemáticos inteligentes de posicionamento geográfico e tempo, o moon irá gerar um alerta sonoro e visual na tela de DashBoard ou na tela do Mapa.<br />
                                            E caso a localidade habilite a notifição via Whatsapp, o mesmo alarme também poderá ser disparado via integração Whatsapp.</p>
                                    </p>

                                </Fieldset>
                            </div>
                            <div className="col-12">
                                {/* <BehavioralConvoyConfig
                                    loading={loading}
                                    config={clonedVehicleConfig}
                                    saveConfig={onSaveConfig}
                                    updateConfig={setClonedVehicleConfig}
                                    configurations={configurations}
                                    selectedBehavioralConvoy={selectedBehavioralConvoy}
                                    setSelectedBehavioralConvoy={setSelectedBehavioralConvoy}
                                    onSaveBehavioralConvoyConfig={onSaveBehavioralConvoyConfig}
                                /> */}
                                <WhatsappClonedVehicleConfig
                                    clonedVehicleConfig={clonedVehicleConfig}
                                    onSaveConfig={onSaveConfig}
                                    setClonedVehicleConfig={setClonedVehicleConfig}
                                />
                            </div>

                        </div>
                    )}
                    <div className="col-12">
                        {selectedLocation !== 0 && loadingCameras ? renderSkeletonDataScroller() :
                            <DataView
                                header="Câmeras da Localidade"
                                value={getFilteredCameraList()}
                                itemTemplate={itemTemplate}
                                layout="grid"
                                emptyMessage="Nenhuma imagem encontrada para o filtro selecionado"
                                rows={12}
                                paginator
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
