import React from 'react';
import '../../styles/legend.css';
import { LegendProps } from '../../types/types';


const Legend: React.FC<LegendProps> = React.memo((props:LegendProps) => {
    // Define o valor máximo ajustado com base nas propriedades recebidas
    const adjustedMax = Math.min(props.maxVehicles, 1000) * props.intensityFactor * (props.blur / 20) * (props.radius / 30) * (props.maxZoom / 15);
    const step = adjustedMax / 6;
    const grades = Array.from({ length: 7 }, (_, i) => Math.round(i * step));

    // Função para gerar a cor correspondente a cada intervalo
    const getColor = (d: number) => {
        return d > grades[5] ? '#FF0000' :
            d > grades[4] ? '#FF4500' :
            d > grades[3] ? '#FFA500' :
            d > grades[2] ? '#FFFF00' :
            d > grades[1] ? '#00FF00' :
            d > grades[0] ? '#00FFFF' :
                            '#0000FF';
    };

    return (
        <div className="legend-container">
            <h3>Legenda</h3>
            <div><i className="color-box" style={{ background: 'green' }}></i> Câmera Ativa</div>
            <div><i className="color-box" style={{ background: 'orange' }}></i> Câmera Ativa (SignalR Inativo)</div>
            <div><i className="color-box" style={{ background: 'red' }}></i> Câmera Inativa</div>
            <br />
            <h4>Heatmap (Veículos)</h4>
            {grades.map((grade, index) => (
                <div key={index}>
                    <i className="color-box" style={{ background: getColor(grade) }}></i>
                    {grade}{grades[index + 1] ? ` – ${grades[index + 1]}` : '+'}
                </div>
            ))}
        </div>
    );
});

export default Legend;
