import React from 'react';
import { SideBarMenuProps } from '../../types/types';


export const SideBarMenu: React.FC<SideBarMenuProps> = (props: SideBarMenuProps) => {
    const getMenuItems = () => [
        {
            label: "Rota de Veículos",
            icon: "pi pi-map",
            command: props.onRouteClick
        },
        {
            label: "Filtros",
            icon: "pi pi-filter",
            command: props.onFilterClick
        },
        {
            label: "Configurações",
            icon: "pi pi-cog",
            command: props.onSettingsClick
        },
        // {
        //     label: "Legenda", // Ícone e ação para a Legenda
        //     icon: "pi pi-info-circle",
        //     command: props.onLegendClick // Aciona o popup de legenda
        // }
    ];

    return (
        <div className="side-bar-content">
            {getMenuItems().map((item, index) => (
                <div key={index} className="side-bar-item" onClick={item.command}>
                    <i className={item.icon}></i>
                    {props.isSideBarOpen && <span>{item.label}</span>}
                </div>
            ))}
        </div>
    );
};
