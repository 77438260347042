import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { environment } from "../../../configuration/environment";
import { isResponseFromSameCamera, isResponseFromSameLocation } from '../../../services/map-services';
import { blankBehavioralConvoyAlert, blankBlackListAlert, blankClonedVehicleAlert, blankConvoyAlert, blankCustomAlarmAlert, blankDssEventSubscriptionAlert, blankNoPlateAlert, blankPlateEventResponse, blankPointABAlert, Camera, cardResponse, location, plateEventResponse, SignalRLiveMapProps } from "../types/types";

import { userHasAccessToLocation } from "../../../services/dashboard-services";

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { DynamicMapCenter } from "./components/dynamic-map-center";
import VisibleCameras from "./components/visible-cameras";
import LocateUserButton from "./components/locate-button";
import HeatmapLayer from "./components/heatmap-layer";
import 'leaflet.heat';
import { DashboardServices } from "../../../services/dashboard-services";
import AudioPlayer from "../../Dashboard/views/audio-data";
import { BlackListDialog } from "../../Dashboard/views/dialog/black-list-dialog";
import { ConvoyAlertDialog } from "../../Dashboard/views/dialog/convoy-alert-dialog";
import { ClonedVehicleAlertDialog } from "../../Dashboard/views/dialog/cloned-vehicle-alert-dialog";
import { PointABAlertDialog } from "../../Dashboard/views/dialog/point-AB-alert-dialog";
import { CustomAlarmAlertDialog } from "../../Dashboard/views/dialog/custom-alarm-alert-dialog";
import { NoPlateAlertDialog } from "../../Dashboard/views/dialog/no-plate-alert-dialog";
import { BehavioralConvoyAlertDialog } from "../../Dashboard/views/dialog/behavioral-convoy-alert-dialog";
import { DssEventSubscriptionDialog } from "../../Dashboard/views/dialog/dss-event-subscription-dialog";
import { BehavioralConvoyAlertResponse, blackListResponse, ClonedVehicleAlertResponse, ConvoyAlertResponse, CustomAlarmAlertResponse, DssEventSubscriptionResponse, NoPlateAlertResponse, PointABAlertResponse } from "../../../models/types";
import Routing from "./components/routing";

// Configurações de ícone padrão do Leaflet
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});


export const SignalRLiveMap = (props: SignalRLiveMapProps): JSX.Element => {
    const _dashboardServices = new DashboardServices();

    const [playAudio, setPlayAudio] = useState(false);
    const [audioFile, setAudioFile] = useState("");

    // Estados
    const [cameraEvents, setCameraEvents] = useState({});
    // const [listcard, setListcard] = useState<cardResponse[]>([]);
    const [readPlate, setReadPlate] = useState<plateEventResponse>(blankPlateEventResponse);
    const [selectedZoom, setSelectedZoom] = useState<number>(7);
    const [selectedLatitude, setSelectedLatitude] = useState<number>(-18.996449);
    const [selectedLongitude, setSelectedLongitude] = useState<number>(-44.444698);
    const [activePopupCameraId, setActivePopupCameraId] = useState<number | null>(null);
    const [vehicleCountsByCamera, setVehicleCountsByCamera] = useState<{ [cameraId: number]: cardResponse }>({});
    const [heatmapPoints, setHeatmapPoints] = useState<[number, number, number][]>([]);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [clickedCamera, setClickedCamera] = useState<boolean>(false); // Indica se uma câmera foi clicada manualmente


    const [blackListDialog, setBlackListDialog] = useState(false);
    const [convoyAlertDialog, setConvoyAlertDialog] = useState(false);
    const [clonedVehicleAlertDialog, setClonedVehicleAlertDialog] = useState(false);
    const [pointABAlertDialog, setPointABAlertDialog] = useState(false);
    const [noPlateAlertDialog, setNoPlateAlertDialog] = useState(false);
    const [customAlarmAlertDialog, setCustomAlarmAlertDialog] = useState(false);
    const [dssEventSubscriptionDialog, setDssEventSubscriptionDialog] = useState(false);
    const [behavioralConvoyAlertDialog, setBehavioralConvoyAlertDialog] = useState(false);
    const [convoyAlert, setConvoyAlert] = useState<ConvoyAlertResponse>(blankConvoyAlert);
    const [clonedVehicleAlert, setClonedVehicleAlert] = useState<ClonedVehicleAlertResponse>(blankClonedVehicleAlert);
    const [noPlateAlert, setNoPlateAlert] = useState<NoPlateAlertResponse>(blankNoPlateAlert);
    const [blackListAlert, setBlakListAlert] = useState<blackListResponse>(blankBlackListAlert);
    const [pointABAlert, setPointABAlert] = useState<PointABAlertResponse>(blankPointABAlert);
    const [customAlarmAlert, setCustomAlarmAlert] = useState<CustomAlarmAlertResponse>(blankCustomAlarmAlert);
    const [behavioralConvoyAlert, setBehavioralConvoyAlert] = useState<BehavioralConvoyAlertResponse>(blankBehavioralConvoyAlert);
    const [dssEventSubscription, setDssEventSubscription] = useState<DssEventSubscriptionResponse>(blankDssEventSubscriptionAlert);

    const [routePoints, setRoutePoints] = useState<[number, number][]>([]); // Pontos da rota para o mapa

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    // Refs para controle de estado
    const lastSelectedCamera = useRef<number>(props.selectedCamera);
    const lastSelectedLocation = useRef<number>(props.selectedLocation);
    const lastReadPlate = useRef<plateEventResponse>(blankPlateEventResponse);
    const lastConnection = useRef<HubConnection | null>(null);

    // Atualizar dados a cada 5 minutos
    useEffect(() => {
        updateCardData();
        const interval = setInterval(updateCardData, 300000); // 5 minutos
        return () => clearInterval(interval);
    }, [props.selecteDate, props.selectedCamera, props.selectedLocation]);

    // Ajustar centralização e zoom do mapa com base na localização/câmera selecionada
    useEffect(() => {
        adjustMapCenter();
    }, [props.selectedLocation, props.selectedCamera]);

    // Inicializar a conexão SignalR
    useEffect(() => {
        initializeSignalRConnection();
    }, []);

    // Iniciar a conexão SignalR
    useEffect(() => {
        if (connection) {
            startSignalRConnection();
        }
    }, [connection, props.isRoutePopupOpen, props.isFilterPopupOpen, props.isSettingsPopupOpen, routePoints]);

    // Atualizar os pontos do heatmap com base nas contagens de veículos
    useEffect(() => {
        generateHeatmapData();
    }, [vehicleCountsByCamera, props.cameras, props.selectedVehicleTypes, props.intensityFactor, props.radius, props.blur, props.maxZoom]);

    // Sincroniza o estado `readPlate` com a ref `lastReadPlate`
    useEffect(() => {
        lastReadPlate.current = readPlate;
    }, [readPlate]);

    useEffect(() => {
        if (props.routeList.length > 0) {
            // Converte `routeResponse` em coordenadas ajustadas para o Leaflet
            const points: [number, number][] = props.routeList
                .map(route => {
                    const [lat, lng] = adjustCoordinates(
                        parseFloat(route.latitude),
                        parseFloat(route.longitude)
                    );
                    return [lat, lng] as [number, number];
                })
                .filter(([lat, lng]) => {
                    // Filtra pontos válidos: latitude e longitude dentro dos limites
                    return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
                });

            setRoutePoints(points); // Atualiza os pontos no estado
        }
    }, [props.routeList]);


    // Funções auxiliares
    const adjustMapCenter = () => {
        if (props.selectedCamera) {
            const camera = props.cameras.find(cam => cam.id === props.selectedCamera);
            if (camera) {
                const [lat, lng] = adjustCoordinates(camera.numLatitude, camera.numLongitude);
                setSelectedLatitude(lat);
                setSelectedLongitude(lng);
                setSelectedZoom(17);
            }
        } else if (props.selectedLocation) {
            const location = props.listLocations.find(loc => loc.id === props.selectedLocation);
            if (location) {
                const [lat, lng] = adjustCoordinates(Number(location.latitude), Number(location.longitude));
                setSelectedLatitude(lat);
                setSelectedLongitude(lng);
                setSelectedZoom(11);
            }
        } else {
            setSelectedLatitude(-18.996449);
            setSelectedLongitude(-44.444698);
            setSelectedZoom(7);
        }
    };

    const initializeSignalRConnection = () => {
        if (!lastConnection.current) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(environment().signalR + "live-connection", {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .build();
            setConnection(newConnection);
            lastConnection.current = newConnection;
        }
    };

    const onPlayAudioWarning = (sound_name: string) => {
        var _mutedSound = localStorage.getItem("mute-sounds") ?? "false";

        if (_mutedSound === "false") {
            setAudioFile(sound_name);
            setPlayAudio(true);
            setTimeout(() => {
                setPlayAudio(false);
            }, 5000);
        }
    }

    const handleBlackListAlert = (blackListResponse: blackListResponse) => {
        setBlakListAlert(blackListResponse);
        setBlackListDialog(true);
        onPlayAudioWarning("black_list");
    }

    const handleConvoyAlert = (convoyAlert: ConvoyAlertResponse) => {
        setConvoyAlert(convoyAlert);
        setConvoyAlertDialog(true);
        onPlayAudioWarning("modo_comboio");
    }

    const handleClonedVehicleAlert = (clonedVehicleAlert: ClonedVehicleAlertResponse) => {
        setClonedVehicleAlert(clonedVehicleAlert);
        setClonedVehicleAlertDialog(true);
        onPlayAudioWarning("cloned_vehicle");
    }

    const handlePointABAlert = (pointABAlert: PointABAlertResponse) => {
        setPointABAlert(pointABAlert);
        setPointABAlertDialog(true);
        onPlayAudioWarning("deslocamento_especial");
    }

    const handleCustomAlarmAlert = (customAlarmAlert: CustomAlarmAlertResponse) => {
        setCustomAlarmAlert(customAlarmAlert);
        setCustomAlarmAlertDialog(true);
        onPlayAudioWarning("alarme_customizado");
    }

    const handleNoPlateAlert = (noPlateAlertResponse: NoPlateAlertResponse) => {
        setNoPlateAlert(noPlateAlertResponse);
        setNoPlateAlertDialog(true);
        onPlayAudioWarning("veículo_sem_placa");
    }

    const handleBehavioralConvoyAlert = (behavioralConvoyAlertResponse: BehavioralConvoyAlertResponse) => {
        setBehavioralConvoyAlert(behavioralConvoyAlertResponse);
        setBehavioralConvoyAlertDialog(true);
        onPlayAudioWarning("modo_comboio_comportamental");
    }

    const handleDssEventSubscriptionAlert = (dssEventSubscription: DssEventSubscriptionResponse) => {
        setDssEventSubscription(dssEventSubscription);
        setDssEventSubscriptionDialog(true);
        onPlayAudioWarning("alarme_customizado");
    }

    const onCloseBlackListDialog = () => {
        setBlackListDialog(false);
        setPlayAudio(false);
    }

    const onCloseConvoyAlertDialog = () => {
        setConvoyAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseClonedVehicleAlertDialog = () => {
        setClonedVehicleAlertDialog(false);
        setPlayAudio(false);
    }

    const onClosePointABAlertDialog = () => {
        setPointABAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseCustomAlarmAlertDialog = () => {
        setCustomAlarmAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseNoPlateAlertDialog = () => {
        setNoPlateAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseBehavioralConvoyAlertDialog = () => {
        setBehavioralConvoyAlertDialog(false);
        setPlayAudio(false);
    }


    const startSignalRConnection = async () => {
        if (connection) {
            if (connection.state === "Disconnected") {
                try {
                    await connection.start();
                    props.setSignalRConnected(true);
                } catch (error) {
                    return;
                }
            }

            if (props.isFilterPopupOpen || props.isSettingsPopupOpen || props.isRoutePopupOpen || routePoints.length > 1) {
                const eventsToDisable = [
                    'camera-report',
                    'black-list-report',
                    'convoy-report',
                    'cloned-vehicle-report',
                    'point-ab-report',
                    'custom-alarm-report',
                    'no-plate-report',
                    'behavioral-convoy-report',
                    'dss-event-subscription',
                ];
                eventsToDisable.forEach(eventName => connection.off(eventName));
            } else {

                connection.on('camera-report', (readPlateResponse: plateEventResponse) => {
                    handleReadPlate(readPlateResponse);
                });

                connection.on('black-list-report', (blackListResponse: blackListResponse) => {
                    if (userHasAccessToLocation(blackListResponse.locationId)) {
                        setPlayAudio(false);
                        setBlackListDialog(false);
                        setBlakListAlert(blankBlackListAlert);
                        handleBlackListAlert(blackListResponse);
                    }
                });

                connection.on(`convoy-report`, (convoyAlertResponse: ConvoyAlertResponse) => {
                    if (userHasAccessToLocation(convoyAlertResponse.locationId)) {
                        setConvoyAlertDialog(false);
                        setPlayAudio(false);
                        setConvoyAlert(blankConvoyAlert);
                        handleConvoyAlert(convoyAlertResponse);
                    }
                });

                connection.on(`cloned-vehicle-report`, (clonedVehicleAlertResponse: ClonedVehicleAlertResponse) => {
                    if (userHasAccessToLocation(clonedVehicleAlertResponse.locationId)) {
                        setClonedVehicleAlertDialog(false);
                        setPlayAudio(false);
                        setClonedVehicleAlert(blankClonedVehicleAlert);
                        handleClonedVehicleAlert(clonedVehicleAlertResponse);
                    }
                });

                connection.on(`point-ab-report`, (pointABAlertResponse: PointABAlertResponse) => {
                    if (userHasAccessToLocation(pointABAlertResponse.locationId)) {
                        setPointABAlertDialog(false);
                        setPlayAudio(false);
                        setPointABAlert(blankPointABAlert);
                        handlePointABAlert(pointABAlertResponse);
                    }
                });

                connection.on(`custom-alarm-report`, (customAlarmAlertResponse: CustomAlarmAlertResponse) => {
                    if (userHasAccessToLocation(customAlarmAlertResponse.locationId)) {
                        setCustomAlarmAlertDialog(false);
                        handleCustomAlarmAlert(customAlarmAlertResponse);
                    }
                });

                connection.on(`no-plate-report`, (noPlateAlertResponse: NoPlateAlertResponse) => {
                    if (userHasAccessToLocation(noPlateAlertResponse.locationId)) {
                        setNoPlateAlertDialog(false);
                        handleNoPlateAlert(noPlateAlertResponse);
                    }
                });

                connection.on(`behavioral-convoy-report`, (behavioralConvoyAlertResponse: BehavioralConvoyAlertResponse) => {
                    if (userHasAccessToLocation(behavioralConvoyAlertResponse.locationId)) {
                        setBehavioralConvoyAlertDialog(false);
                        handleBehavioralConvoyAlert(behavioralConvoyAlertResponse);
                    }
                });

                connection.on(`dss-event-subscription`, (response: DssEventSubscriptionResponse) => {
                    if (userHasAccessToLocation(response.locationId)) {
                        setDssEventSubscriptionDialog(false);
                        handleDssEventSubscriptionAlert(response);
                    }
                });
            }
        }
    };



    const generateHeatmapData = () => {
        const heatmapData: [number, number, number][] = props.cameras.map((camera) => {
            const vehicleCounts = vehicleCountsByCamera[camera.id];

            if (vehicleCounts) {
                // Agora usamos diretamente os tipos selecionados em vez de fazer o mapeamento desnecessário
                const totalVehicles = props.selectedVehicleTypes.reduce((acc, vehicleType) => {
                    // Verifique se o valor mapeado existe em vehicleCounts e some ao total
                    return acc + (vehicleCounts[vehicleType] || 0);  // Já acessando diretamente os campos, como "quantityCar"
                }, 0);

                if (totalVehicles > 0) {
                    const intensity = Math.log(totalVehicles) / props.intensityFactor; // Aplicando o fator de intensidade

                    const [lat, lng] = adjustCoordinates(Number(camera.numLatitude), Number(camera.numLongitude));
                    return [lat, lng, intensity];
                }
            }
            return null;
        }).filter(Boolean) as [number, number, number][]; // Remove null values

        setHeatmapPoints(heatmapData);
    };


    const updateCardData = () => {
        _dashboardServices.getCardPerCameraData(props.selecteDate).then((response: any) => {
            if (response.status === 200) {
                const updatedVehicleCounts = response.data.reduce((acc: any, card: cardResponse) => {
                    acc[card.cameraId] = card;
                    return acc;
                }, {});
                setVehicleCountsByCamera(updatedVehicleCounts);
                // setListcard(response.data);
            } else {
                setVehicleCountsByCamera({});
                // setListcard([]);
            }
        });
    };

    const adjustCoordinates = (lat: number, lng: number): [number, number] => {
        return [lat > 0 ? -lat : lat, lng > 0 ? -lng : lng];
    };

    const handleReadPlate = (readPlateResponse: plateEventResponse) => {
        if (isResponseFromSameLocation(readPlateResponse.locationId, lastSelectedLocation.current) &&
            isResponseFromSameCamera(readPlateResponse.cameraId, lastSelectedCamera.current)) {
            const camera = props.cameras.find(cam => cam.id === readPlateResponse.cameraId);
            if (camera) {
                const [lat, lng] = adjustCoordinates(camera.numLatitude, camera.numLongitude);
                setReadPlate(readPlateResponse);
                setCameraEvents((prevEvents) => ({
                    ...prevEvents,
                    [camera.id]: readPlateResponse,
                }));

                setActivePopupCameraId(camera.id);
                setTimeout(() => setActivePopupCameraId(null), 3000);
            }
        }
    };

    const handleMarkerClick = (val: boolean) => {
        setClickedCamera(val); // Marca como clicado
        // Cancela qualquer temporizador ativo e define um novo para 3 minutos
        if (val) {
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setClickedCamera(false); // Permite novamente eventos automáticos após 3 minutos
                setActivePopupCameraId(null);
            }, 180000);
        } else {
            if (timerRef.current) clearTimeout(timerRef.current);
        }
    };

    const clearRoute = () => {
        setRoutePoints([])
    }


    if (props.loading) {
        return <div>Loading map...</div>;
    }

    return (
        <React.Fragment>
            <React.Fragment>
                <div className="map-wrapper">
                    <MapContainer center={[selectedLatitude, selectedLongitude]} zoom={selectedZoom} className="leaflet-map-container">
                        <TileLayer
                            attribution='&copy; OpenStreetMap contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />

                        {/* Componente de Rotas */}
                        {routePoints.length > 1 && <Routing waypoints={routePoints} />}

                        {routePoints.length > 1 && <>
                            <div className="button-route">
                                <button
                                    onClick={clearRoute}
                                >
                                    <i className="pi pi-map-marker" style={{ fontSize: '1.5em' }}></i> {/* Ícone do PrimeIcons */}
                                    Limpar rota {/* Texto ao lado do ícone */}
                                </button>
                            </div>
                        </>}

                        {/* Heatmap Layer */}
                        <HeatmapLayer
                            heatmapPoints={heatmapPoints}
                            radius={props.radius}   // Usa o valor ajustado pelo slide
                            blur={props.blur}       // Usa o valor ajustado pelo slide
                            maxZoom={props.maxZoom} // Usa o valor ajustado pelo slide
                        />

                        <LocateUserButton />

                        <DynamicMapCenter latitude={selectedLatitude} longitude={selectedLongitude} zoom={selectedZoom} />

                        <VisibleCameras
                            clickedCamera={clickedCamera}
                            cameraEvents={cameraEvents}
                            readPlate={lastReadPlate.current}
                            cameras={props.cameras}
                            onClickedCamera={handleMarkerClick}
                            activePopupCameraId={activePopupCameraId}
                            vehicleCountsByCamera={vehicleCountsByCamera}
                        />

                    </MapContainer>
                </div>
            </React.Fragment>
            <React.Fragment>
                <AudioPlayer play={playAudio} soundName={audioFile} />
                <BlackListDialog blackListAlert={blackListAlert} blackListDialog={blackListDialog} setBlackListDialog={onCloseBlackListDialog} />
                <ConvoyAlertDialog convoyAlert={convoyAlert} convoyAlertDialog={convoyAlertDialog} setConvoyAlertDialog={onCloseConvoyAlertDialog} />
                <ClonedVehicleAlertDialog clonedVehicleAlert={clonedVehicleAlert} clonedVehicleAlertDialog={clonedVehicleAlertDialog} setClonedVehicleAlertDialog={onCloseClonedVehicleAlertDialog} />
                <PointABAlertDialog pointABAlert={pointABAlert} pointABAlertDialog={pointABAlertDialog} setPointABAlertDialog={onClosePointABAlertDialog} />
                <CustomAlarmAlertDialog customAlarmAlert={customAlarmAlert} customAlarmAlertDialog={customAlarmAlertDialog} setCustomAlarmAlertDialog={onCloseCustomAlarmAlertDialog} />
                <NoPlateAlertDialog noPlateAlert={noPlateAlert} noPlateAlertDialog={noPlateAlertDialog} setNoPlateAlertDialog={onCloseNoPlateAlertDialog} />
                <BehavioralConvoyAlertDialog behavioralConvoyAlert={behavioralConvoyAlert} behavioralConvoyAlertDialog={behavioralConvoyAlertDialog} setBehavioralConvoyAlertDialog={onCloseBehavioralConvoyAlertDialog} />
                <DssEventSubscriptionDialog dssEventSubscriptionAlert={dssEventSubscription} dssEventSubscriptionDialog={dssEventSubscriptionDialog} setDssEventSubscriptionDialog={setDssEventSubscriptionDialog} />
            </React.Fragment>
        </React.Fragment>
    );
};
