import React from 'react';
import '../../styles/slide.css';
import { SlideProps } from '../../types/types';

export const Slide: React.FC<SlideProps> = ({
    intensityFactor, setIntensityFactor,
    radius, setRadius,
    blur, setBlur,
    maxZoom, setMaxZoom
}) => {

    const handleIntensityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIntensityFactor(parseFloat(e.target.value));
    };

    const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRadius(parseInt(e.target.value, 10));
    };

    const handleBlurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBlur(parseInt(e.target.value, 10));
    };

    const handleMaxZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMaxZoom(parseInt(e.target.value, 10));
    };

    return (
        <div className="slide-container">
            <h3>Heatmap Settings</h3>
            <div className="categoria slide-item">
                <label>Intensidade: {intensityFactor}</label>
                <input type="range" min="0.1" max="5" step="0.1" value={intensityFactor} onChange={handleIntensityChange} />
            </div>
            <div className="categoria slide-item">
                <label>Raio (Radius): {radius}</label>
                <input type="range" min="15" max="50" step="1" value={radius} onChange={handleRadiusChange} />
            </div>
            <div className="categoria slide-item">
                <label>Blur: {blur}</label>
                <input type="range" min="15" max="35" step="1" value={blur} onChange={handleBlurChange} />
            </div>
            <div className="categoria slide-item">
                <label>Max Zoom: {maxZoom}</label>
                <input type="range" min="15" max="19" step="1" value={maxZoom} onChange={handleMaxZoomChange} />
            </div>
        </div>
    );
};
