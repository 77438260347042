import React, { Fragment, useState, useEffect } from "react";
import CameraMarker from "./camera-marker";
import { Camera, VisibleCamerasProps } from "../../types/types";
import { useMapEvents, useMap } from "react-leaflet";
import L from 'leaflet';

// Hook para ajustar coordenadas, se necessário
const adjustCoordinates = (lat: number, lng: number): [number, number] => {
    return [lat > 0 ? -lat : lat, lng > 0 ? -lng : lng];
};

// Hook para determinar câmeras visíveis no mapa com base nos eventos de movimentação e zoom
const useVisibleCameras = (cameras: Camera[]): Camera[] => {
    const map = useMap();  // Hook para acessar o mapa
    const [visibleCameras, setVisibleCameras] = useState<Camera[]>([]);

    const handleVisibleCameras = () => {
        const bounds = map.getBounds();  // Obtém os limites atuais do mapa

        // Filtra as câmeras que estão dentro dos limites visíveis do mapa
        const filteredCameras = cameras.filter((camera) => {
            const [adjustedLatitude, adjustedLongitude] = adjustCoordinates(camera.numLatitude, camera.numLongitude);
            const latLng = L.latLng(adjustedLatitude, adjustedLongitude);
            return bounds.contains(latLng);  // Verifica se a câmera está dentro dos limites do mapa
        });

        setVisibleCameras(filteredCameras);  // Atualiza as câmeras visíveis
    };

    useMapEvents({
        // Eventos que disparam o recalculo das câmeras visíveis
        moveend: () => handleVisibleCameras(),   // Quando o movimento do mapa termina
        zoomend: () => handleVisibleCameras(),   // Quando o zoom do mapa termina
    });

    useEffect(() => {
        handleVisibleCameras();  // Filtra câmeras quando o componente é montado
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameras]);

    return visibleCameras;
};

// Componente que exibe câmeras visíveis no mapa
const VisibleCameras: React.FC<VisibleCamerasProps> = React.memo((props: VisibleCamerasProps) => {
    const visibleCameras = useVisibleCameras(props.cameras);  // Adiciona um delay de 500ms na filtragem das câmeras

    return (
        <Fragment>
            {visibleCameras.map((camera) => (
                <CameraMarker
                    clickedCamera={props.clickedCamera}
                    cameraEvents={props.cameraEvents}
                    key={camera.id}
                    camera={camera}
                    readPlate={props.readPlate}
                    activePopupCameraId={props.activePopupCameraId}
                    onClickedCamera={props.onClickedCamera}  // Passa a função de seleção de câmera como prop
                    vehicleCount={props.vehicleCountsByCamera[camera.id]}  // Passa apenas a contagem de veículos relativa à câmera
                />
            ))}
        </Fragment>
    );
});

export default VisibleCameras;
