import axios from "axios";
import { environment } from "../configuration/environment";
import { cameraPerformance, exceptionResponse, plateEventResponse } from "../models/types";
import { SessionStorageUtils } from "../shared/session-storage/session-storage-utils";
import { AuthServices } from "./utils/auth-services";


const sessionStorageUtils = new SessionStorageUtils();

export class DashboardServices {
    _authServices = new AuthServices();

    getCardData = (date: Date, location: number | null, cameraId: number | null) => {
        return axios.get(environment().baseUrl + environment().version + "/dashboard/cards", {
            params: {
                date: date.toLocaleString("en-us", { day: "2-digit", month: "2-digit", year: "numeric" }),
                locationId: location === 0 ? null : location,
                cameraId: cameraId === 0 ? null : cameraId,
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    };

    getCardPerCameraData = (date: Date) => {
        return axios.get(environment().baseUrl + environment().version + "/dashboard/card-per-camera", {
            params: {
                date: date.toLocaleString("en-us", { day: "2-digit", month: "2-digit", year: "numeric" }),
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    };


    getIntegrationCardData = (date: Date, location: number | null, cameraId: number | null, integrationType: string | null) => {
        return axios.get(environment().baseUrl + environment().version + "/dashboard/integration-card", {
            params: {
                date: date.toLocaleString("en-us", { day: "2-digit", month: "2-digit", year: "numeric" }),
                locationId: location === 0 ? null : location,
                cameraId: cameraId === 0 ? null : cameraId,
                integrationType: integrationType === "" ? null : integrationType,
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    };


    getCameraPerformanceGraph = (date: Date, location: number | null, cameraId: number | null) => {
        return axios.get(environment().baseUrl + environment().version + "/dashboard/top-ten-camera", {
            params: {
                date: date.toLocaleString("en-us", { day: "2-digit", month: "2-digit", year: "numeric" }),
                locationId: location === 0 ? null : location,
                cameraId: cameraId === 0 ? null : cameraId,
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    };
}


export const getCameraPerformanceGraph = (date: Date, location: number | null, camera: number | null): cameraPerformance[] => {
    const _authServices = new AuthServices();
    let camerasPerformance: cameraPerformance[] = [];

    axios
        .get(environment().baseUrl + environment().version + "/dashboard/top-ten-camera", {
            params: {
                date: date.toLocaleString("en-us", { day: "2-digit", month: "2-digit", year: "numeric" }),
                locationId: location === 0 ? null : location,
                cameraId: camera === 0 ? null : camera,
            },
            headers: {
                Authorization: _authServices.GetUserToken(),
            },
        })
        .then((result) => {
            result.data.forEach((x: any) => {
                camerasPerformance.push({ description: x.description, quantity: x.quantity });
            });
        });
    return camerasPerformance;
}

export const isResponseFromSameLocation = (responseLocationId: number, location: number): boolean => {
    if (location === 0 && userHasAccessToLocation(responseLocationId)) {
        return true;
    }

    return responseLocationId === location && userHasAccessToLocation(responseLocationId);
}

export const isResponseFromSameCamera = (responsecameraId: number, cameraId: number): boolean => {
    if (cameraId === 0) {
        return true;
    }

    return responsecameraId === cameraId;
}

export const userHasAccessToLocation = (locationId: number): boolean => {

    const isAdminUser: boolean = sessionStorageUtils.isAdminUser();
    const userAllowedLocations = JSON.parse(sessionStorageUtils.getLocations()) as number[];

    if (isAdminUser) return true;

    if (userAllowedLocations.find((x => x === locationId))) return true;

    return false;
}
