import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";

export class ClonedVehicleServices {
    baseControllerUrl: string = environment().baseUrl + environment().version + "/cloned-vehicle";
    _authServices = new AuthServices();

    getEvents(cameraId: number, locationId: number) {
        return axios.get(this.baseControllerUrl + "/get-events", {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
            params: {
              // eslint-disable-next-line eqeqeq
              cameraId: cameraId == 0 ? null : cameraId,
              // eslint-disable-next-line eqeqeq
              locationId: locationId == 0 ? null : locationId
            }
        });
    }

    getLocationClonedVehicleTime(locationId: number) {
        return axios.get(this.baseControllerUrl + '/' + locationId, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    updateClonedVehicleTime(locationId: number, startTime: Date, stopTime: Date, whatsappGroupdId: number) {
        return axios.put(this.baseControllerUrl + '/update-cloned-vehicle-time/' + locationId, {}, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
            params: {
                startTime: startTime.toLocaleString(),
                stopTime: stopTime.toLocaleString(),
                whatsappGroupId: whatsappGroupdId
            }
        });
    }
}
