import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { RoutingProps } from "../../types/types";
import '../../styles/routing.css';

const Routing: React.FC<RoutingProps> = (props: RoutingProps) => {
  const map = useMap(); // Obtem o mapa do React-Leaflet

  useEffect(() => {
    if (props.waypoints.length > 1) {
      // Cria a instância de controle de rota
      const routingControl = L.Routing.control({
        waypoints: props.waypoints.map(([lat, lng]) => L.latLng(lat, lng)),
        router: L.Routing.osrmv1({
          serviceUrl: "https://router.project-osrm.org/route/v1",
        }),
        lineOptions: {
          styles: [
            {
              color: "blue",
              opacity: 0.6,
              weight: 4,
            },
          ],
          extendToWaypoints: true, // Garante que a linha passe por todos os waypoints
          missingRouteTolerance: 10, // Tolerância para rotas faltantes
        },
        addWaypoints: false,
        fitSelectedRoutes: true,
        showAlternatives: false,
      }).addTo(map);

      return () => {
        map.removeControl(routingControl); // Remove o controle ao desmontar
      };
    }
  }, [map, props.waypoints]);

  return null;
};

export default Routing;
