export const emptyCamera = {
    idCameraModelo: 1,
    indAtivo: true,
    integrations: [],
    numImageMode: 0,
} as camera;

export const emptyIntegration = {
    idIntegracao: 2,
    indAtivo: true,
} as cameraIntegration;

export interface CameraIntelligenceResponse {
    camera: string;
    cameraId: number;
    indConvoy: boolean;
    indBikerBehavior: boolean;
    cameraAddress: string;
    indNoPlate: boolean;
    indBehavioralConvoy: boolean;
}

export interface CameraClonedVehicleResponse {
    camera: string;
    cameraId: number;
    cameraAddress: string;
    indClonedVehicle: boolean;
}

export interface camera {
    id: number;
    dscCamera: string;
    nomUF: string;
    nomLocalidade: string;
    dscUsuario: string;
    dscSenha: string;
    dscEndereco: string;
    numLatitude: string;
    numLongitude: string;
    dscUrl: string;
    numPorta: number;
    numImageMode: number;
    idLocalidade: number;
    velocidadeMaxima: number;
    nomCameraModelo: string;
    idCameraModelo: number;
    indAtivo: boolean;
    indSignalR: boolean;
    integrations: cameraIntegration[];
}

export interface cameraIntegration {
    idCameraIntegracao: number;
    idIntegracao: number;
    nomeIntegracao: string;
    chaveIntegracao: string;
    indAtivo: boolean;
}
