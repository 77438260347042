import React, { useState, useCallback } from "react";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { translateColor } from "../../../services/utils/input-services";
import { VehicleDetection, VehicleDetectionListDataTableProps } from "../types/types";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import jsPDF from "jspdf";
import logo from "../../../wwroot/img/olho-vivo-logo.png";

export const VehicleDetectionListDataTable = React.memo((props: VehicleDetectionListDataTableProps) => {
    // Estado para armazenar a seleção dos itens
    const [selectedVehicles, setSelectedVehicles] = useState<VehicleDetection[]>([]);

    // Memoiza o header para evitar re-renderizações desnecessárias
    const header = useCallback(() => (
        <div className="table-header">
            <h5 className="p-m-2">Veículos</h5>
        </div>
    ), []);

    // Memoiza as funções de template das colunas
    const licenseBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return <>{vehicle.licensePlate}</>;
    }, []);

    const nameBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return <>{vehicle.cameraName}</>;
    }, []);


    const detectionTimeBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return <>{formatDateTime(vehicle.detectionTime)}</>;
    }, []);

    const locationBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return <>{vehicle.locationName}</>;
    }, []);

    const colorBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        const colorStyle = {
            backgroundColor: vehicle.vehicleColor.toLowerCase(),
            width: '20px',
            height: '20px',
            display: 'inline-block',
            marginRight: '8px',
            borderRadius: '4px',
        };

        return (
            <>
                <i style={colorStyle}></i>
                {translateColor(vehicle.vehicleColor)}
            </>
        );
    }, []);

    const previewImageBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return (
            <div style={{ textAlign: "center" }}>
                {vehicle.imagePath ? (
                    <a href={vehicle.imagePath} download target="_blank" rel="noopener noreferrer">
                        <img
                            src={vehicle.imagePath}
                            alt="Pré-visualização"
                            style={{
                                width: '50px',
                                height: 'auto',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                        />
                    </a>
                ) : (
                    <span>Sem imagem</span>
                )}
            </div>
        );
    }, []);

    const typeBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return <>{vehicle.vehicleType}</>;
    }, []);

    const brandBodyTemplate = useCallback((vehicle: VehicleDetection) => {
        return <>{vehicle.vehicleBrand}</>;
    }, []);

    // Funções de formatação de data
    const formatDateTime = useCallback((input: Date): string => {
        return input.toLocaleString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    }, []);

    const formatDateTime2String = useCallback((input: string): string => {
        return input
            .replace(/\//g, '') // Remove as barras
            .replace(', ', '_') // Substitui ", " por "_"
            .replace(/:/g, ''); // Remove os dois-pontos
    }, []);

    // Função para exportar para CSV
    const exportToCSV = useCallback(() => {
        if (!(props.vehicleHistory.length > 1)) { return; }
        const formattedDate = formatDateTime(new Date());
        const formattedDateTime = formatDateTime2String(formattedDate);

        // Cabeçalhos do CSV
        const headers = ["Placa", "Câmera", "Localidade", "Marca", "Cor", "Tipo", "Data"];

        // Constrói as linhas do CSV
        const csvRows = props.vehicleHistory.map((row) => [
            row.licensePlate,
            row.cameraName,
            row.locationName,
            row.vehicleBrand,
            row.vehicleColor,
            row.vehicleType,
            row.detectionTime,
        ]);

        // Adiciona os cabeçalhos no início do CSV
        csvRows.unshift(headers);

        // Adiciona o BOM e transforma as linhas em strings CSV, separadas por ponto e vírgula
        const csvContent = "\uFEFF" + csvRows.map((row) => row.join(";")).join("\n");

        // Cria um blob para download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        // Cria um link de download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${props.selectedPlate}_${formattedDateTime}_datatable.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [props.vehicleHistory, props.selectedPlate, formatDateTime, formatDateTime2String]);

    // Função para exportar para PDF
    const exportToPDF = useCallback(() => {
        if (!(props.vehicleHistory.length > 1)) { return; }
        const doc = new jsPDF();
        const tableColumn = ["Placa", "Câmera", "Localidade", "Marca", "Cor", "Tipo", "Data"];
        const tableRows = props.vehicleHistory.map((row) => [
            row.licensePlate,
            row.cameraName,
            row.locationName,
            row.vehicleBrand,
            row.vehicleColor,
            row.vehicleType,
            row.detectionTime,
        ]);

        const formattedDate = formatDateTime(new Date());
        const formattedDateTime = formatDateTime2String(formattedDate);

        // Adicione o título centralizado
        const pageWidth = doc.internal.pageSize.getWidth();
        const title = `Histórico para o veículo de placa ${props.selectedPlate}`;
        const textWidth = doc.getTextWidth(title);
        const textX = (pageWidth - textWidth) / 2;

        doc.setFontSize(14);
        doc.text(title, textX, 10);

        // Adicione a imagem ao PDF
        const imgWidth = 50;
        const imgHeight = 15;
        doc.addImage(logo, "PNG", 15, 20, imgWidth, imgHeight);

        // Configura a fonte e o tamanho para o texto da data
        doc.setFontSize(10);
        doc.text(
            `Período: ${props.initialDate != null ? formatDateTime(props.initialDate) : "não setado"
            } - ${props.finalDate != null ? formatDateTime(props.finalDate) : formattedDate
            }`,
            pageWidth - 15,
            25,
            { align: 'right' }
        );

        // Configura a fonte e o tamanho para o texto da data
        doc.text(`Relatório gerado em ${formattedDate}`, pageWidth - 15, 35, { align: 'right' });

        // Cria a tabela
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 40,
        });

        // Salva o PDF
        doc.save(`${props.selectedPlate}_${formattedDateTime}_datatable.pdf`);
    }, [props.vehicleHistory, props.selectedPlate, props.initialDate, props.finalDate, formatDateTime, formatDateTime2String]);

    // Memoiza os templates da barra de ferramentas
    const leftToolbarTemplate = useCallback(() => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={props.globalFilter || ""}
                        onInput={(e: any) => {
                            const newValue = e.target.value;
                            if (props.globalFilter !== newValue) {
                                props.setGlobalFilter(newValue);
                            }
                        }}
                        placeholder="Procurar..."
                    />
                </span>
            </React.Fragment>
        );
    }, [props.globalFilter, props.setGlobalFilter]);

    const rightToolbarTemplate = useCallback(() => {
        return (
            <React.Fragment>
                <Button label="Gerar PDF" icon="pi pi-file-pdf" className="p-button-text" onClick={exportToPDF} />
                <Button label="Gerar CSV" icon="pi pi-file-pdf" className="p-button-text" onClick={exportToCSV} />
            </React.Fragment>
        );
    }, [exportToPDF, exportToCSV]);

    return (
        <React.Fragment>
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            {/* Tabela de Resultados */}
            <DataTable
                value={props.vehicleHistory}
                paginator
                rows={10}
                loading={props.loading}
                selection={selectedVehicles}
                onSelectionChange={(e: any) => setSelectedVehicles(e.value)}
                selectionMode="multiple"
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} veículos localizados"
                globalFilter={props.globalFilter || ""}
                emptyMessage="Nenhum veículo encontrado."
                header={header()}
                multiSortMeta={[
                    { field: "licensePlate", order: 1 },
                    { field: "detectionTime", order: -1 },
                    { field: "cameraName", order: 1 },
                    { field: "locationName", order: 1 },
                ]}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                <Column field="licensePlate" header="Placa" sortable body={licenseBodyTemplate}></Column>
                <Column field="detectionTime" header="Data e Hora" sortable body={detectionTimeBodyTemplate}></Column>
                <Column field="cameraName" header="Câmera" sortable body={nameBodyTemplate}></Column>
                <Column field="locationName" header="Localidade" sortable body={locationBodyTemplate}></Column>
                <Column field="vehicleBrand" header="Marca" sortable body={brandBodyTemplate}></Column>
                <Column field="vehicleColor" header="Cor" sortable body={colorBodyTemplate}></Column>
                <Column field="vehicleType" header="Tipo" sortable body={typeBodyTemplate}></Column>
                <Column field="previewImage" header="Pré-visualização" body={previewImageBodyTemplate}></Column>
            </DataTable>
        </React.Fragment>
    );
});
